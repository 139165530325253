import SlideMenu from "../leftdivisonComponents/SlideMenu";
import Logo from '../../assest/powerbets-logo.png'



const LeftDivison = () => {

    return (
        <div className="left-divison ">
            <SlideMenu />
        </div>
    )
}
export default LeftDivison;