import React, { useContext } from "react";
import context from "../context/createContext";

const BoxCard = ({ tittle, time, children, icon }) => {
  const { isMobile, isShowTv, setIsShowTv } = useContext(context);
  return (
    <div className="box-card ">
      <div className="card-header p-2">
        <div className="row border1">
          <div className="col-lg-7 border1 d-flex justify-content-between">
            <section className="w-75 border1">
              {" "}
              {tittle}
              <p>{time}</p>
            </section>
            {isMobile ? (
              <button
                onClick={() => setIsShowTv(!isShowTv)}
                className="border-0 bg-transparent"
              >
                {icon}{" "}
              </button>
            ) : (
              icon
            )}
          </div>
        </div>
      </div>
      <div className="content overflow-hidden">{children}</div>
    </div>
  );
};

export default BoxCard;
