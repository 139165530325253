import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { CiMenuKebab } from "react-icons/ci";
import SportsCardList from '../rightdivisoncomponents/SportsCardList';
import UserProfile from '../rightdivisoncomponents/UserProfile';
import context from '../context/createContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import ChipSettingPage from '../pages/ChipSettingPage';
import AccountStatemantPage from '../pages/AccountStatemantPage';
import ProfitAndLoss from '../pages/Profit&LossPage';
import ResultPage from '../pages/ResultPage';
import MarketPage from '../pages/MarketPage';
import LoginPage from '../pages/LoginPage';
import ProtectedRoute from './../rightdivisoncomponents/ProtectedRoute'
import BetSlipBox from '../rightdivisoncomponents/BetSlipBox';


const RightDivison = ({ setToken }) => {

  return (
    <div className='right-divison  position-relative  '>
      <div className='wraper  px-0 p-1 p-lg-4   '>
        <UserProfile setToken={setToken} />
        <Routes>
          <Route path='/home' element={<ProtectedRoute element={<HomePage />} />} />
          <Route path='/change-password' element={<ProtectedRoute element={<ChangePasswordPage />} />} />
          <Route path='/chip-Setting' element={<ProtectedRoute element={<ChipSettingPage />} />} />
          <Route path='/account-statement' element={<ProtectedRoute element={<AccountStatemantPage />} />} />
          <Route path='/profit-and-loss' element={<ProtectedRoute element={<ProfitAndLoss />} />} />
          <Route path='/results' element={<ProtectedRoute element={<ResultPage />} />} />
          <Route path='/market/:id' element={<ProtectedRoute element={<MarketPage />} />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
      </div>
    </div>
  )

}

export default RightDivison
