import React, { useContext, useEffect, useState } from 'react'
import context from '../context/createContext';

const OneClickBetEdite = () => {
  const [onlyRead, setOnlyRead] = useState(true);
  const [selectedInput, setSelectedInput] = useState(2);
  const [inputvalue, setInputValue] = useState([10, 20, 100, 10]);
  const { setIsModeldisplay } = useContext(context);
  // console.log(selectedInput);

  const handelChange = (e, index) => {
    const newArray = [...inputvalue]
    newArray[index] = e.target.value;
    setInputValue(newArray)
  }

  const handelclose = () => {
    setIsModeldisplay(false)
  }

  const handelSave = () => {
    setOnlyRead(true)
    localStorage.setItem("betstake", JSON.stringify(inputvalue))
  }

  useEffect(() => {
    const exitingValue = localStorage.getItem("betstake")
    setInputValue(JSON.parse(exitingValue) || [10, 20, 100, 10])

  }, [])


  // console.log(inputvalue);

  return (
    <div className='one-click-edite '>
      <div className=' d-flex' >
        <input onChange={(e) => handelChange(e, 0)} onClick={() => setSelectedInput(1)} readOnly={onlyRead} className={onlyRead && selectedInput == 1 ? "selected" : onlyRead == false && selectedInput == 1 ? "selected-input" : ""} type="number" placeholder='Enter Stake Value' value={inputvalue[0]} />
        <input onChange={(e) => handelChange(e, 1)} onClick={() => setSelectedInput(2)} readOnly={onlyRead} className={onlyRead && selectedInput == 2 ? "selected" : onlyRead == false && selectedInput == 2 ? "selected-input" : ""} type="number" placeholder='Enter Stake Value' value={inputvalue[1]} />
        <input onChange={(e) => handelChange(e, 2)} onClick={() => setSelectedInput(3)} readOnly={onlyRead} className={onlyRead && selectedInput == 3 ? "selected" : onlyRead == false && selectedInput == 3 ? "selected-input" : ""} type="number" placeholder='Enter Stake Value' value={inputvalue[2]} />
        <input onChange={(e) => handelChange(e, 3)} onClick={() => setSelectedInput(4)} readOnly={onlyRead} className={onlyRead && selectedInput == 4 ? "selected" : onlyRead == false && selectedInput == 4 ? "selected-input" : ""} type="number" placeholder='Enter Stake Value' value={inputvalue[3]} />
      </div>
      <div className='footer d-flex justify-content-end mt-3'>
        <button onClick={() => handelclose()}>close</button>

        {onlyRead ? <button onClick={() => setOnlyRead(false)}>edit</button> : <button onClick={() => handelSave()}>save</button>}
      </div>
    </div>
  )
}

export default OneClickBetEdite
