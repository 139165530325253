import React, { useEffect, useState } from 'react'
import { getMatchedBets, GetUserLiability } from '../dataBaseService/service'
import { convertISOToCustomDate } from '../toolFunction'

const MatchedBets = ({ insitialUserDetails, marketId }) => {
  const [matchedBets, setmatchedBets] = useState([])
  const Token = sessionStorage.getItem("token")

  const getMatchedBetData = async () => {
    try {
      let res = await getMatchedBets(Token, marketId);
      console.log(res);

      if (res && res.status == 200) {
        let data = res.data.betHistory

        console.log(data);


        // Group by 'trade'
        const groupedByTrade = data.reduce((acc, obj) => {
          if (!acc[obj.trade]) {
            acc[obj.trade] = [];
          }
          acc[obj.trade].push(obj);
          return acc;
        }, {});

        const result = Object.values(groupedByTrade);
        console.log(result);

        setmatchedBets(result)
      }
    } catch (error) {

    }
  }


  useEffect(() => {
    getMatchedBetData()
  }, [insitialUserDetails])






  return (
    <div className="matched-bets mb-3">
      <div className="header">
        <h1>MATCHED BET</h1>
      </div>
      <div className="content bg-white ">
        {matchedBets.length > 0 ?
          <div>
            <table className='w-100'>
              <thead>
                <tr>
                  <th>{insitialUserDetails ? insitialUserDetails.marketName : ""}</th>
                  <th>ODDS</th>
                  <th>Stake</th>
                  <th>P/L</th>

                </tr>
              </thead>
              <tbody>
                {matchedBets.map((array) => {

                  return (
                    <>
                      <tr className='text-start'><td colSpan={4} className='cat-td status'><code >{array[0].trade}</code></td></tr>
                      {array.map((bets, index) => {
                        return (
                          <tr key={index} style={{ background: bets.type == "back" || bets.type == "yes" ? "#AEDBFB" : "#FFADBD" }}>
                            <td>
                              <section>{bets.runnerName}
                              </section><section>{bets.trade}
                              </section>
                              <section>
                                {convertISOToCustomDate(bets.betDate)}
                              </section>
                            </td>
                            <td>{bets.odds}</td>
                            <td>{bets.stake}</td>
                            <td>{bets.type == "back" ? bets.profit : bets.loss}</td>
                          </tr>
                        )
                      })}
                    </>
                  )
                })}


              </tbody>
            </table>

          </div>
          : <div className='bg-dark text-white p-2'>there are cureently no matched bets</div>
          // <div>
          //   <table className='w-100'>
          //     <thead>
          //       <tr>
          //         <th> {matchedBets[0].
          //           marketName
          //         }</th>
          //         <th>ODDS</th>
          //         <th>Stake</th>
          //         <th>P/L</th>
          //       </tr></thead>
          //     <tbody>
          //       {matchedBets.map((bets) => {

          //         return (
          //           <tr style={{ background: bets.type == "back" ? "#AEDBFB" : "#FFADBD" }}>
          //             <td>
          //               <section>{bets.runnerName}
          //               </section><section>{bets.trade}
          //               </section>
          //               <section>
          //                 {convertISOToCustomDate(bets.betDate)}
          //               </section>
          //             </td>
          //             <td>{bets.odds}</td>
          //             <td>{bets.stake}</td>
          //             <td>{bets.type == "back" ? bets.profit : bets.loss}</td>
          //           </tr>
          //         )
          //       })}
          //     </tbody>
          //   </table>



          // </div>

        }
      </div>
    </div>

  )

}

export default MatchedBets
