import React, { useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const initialData = { oldPassword: "", newPassword: "", confirmPassword: "" };
const initialactive = {
  oldPassword: false,
  newPassword: false,
  confirmPassword: false,
};

function validatePassword(password) {
  if (!/[A-Z]/.test(password)) {
    return "Password Needs At Least One Uppercase Letter..";
  }

  // Check for at least one number
  if (!/[0-9]/.test(password)) {
    return "Password Needs At Least One Number.";
  }

  // If all checks pass
  return "valid";
}

// Check for at least one uppercase letter

// Example usage

const ChangePasswordPage = () => {
  const [passwordfild, setPasswordfild] = useState(initialData);
  const [firstActive, setFirstActive] = useState(initialactive);
  const token = sessionStorage.getItem("token");

  const handelClear = () => {
    setPasswordfild(initialData);
    setFirstActive(initialactive);
  };
  const handelFocous = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
  };

  const handleChangePass = async () => {
    const { oldPassword, newPassword, confirmPassword } = passwordfild;

    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    let data = JSON.stringify({
      oldPass: oldPassword,
      newPass: confirmPassword,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.spacex247.com/user/changePass",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const resp = await axios.request(config);
      if (resp.status === 200) {
        toast.success("Password Changed Successfully!");
      } else {
        toast.error(`Unexpected status: ${resp.status}`);
      }
    } catch (error) {
      if (error.response) {
        toast.error(
          `Error: ${error.response.data.message || "Something went wrong."}`
        );
      } else if (error.request) {
        toast.error("No response from the server. Please try again later.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFirstActive({ ...firstActive, [name]: true });
    setPasswordfild({ ...passwordfild, [name]: value });
  };
  // console.log(passwordfild);

  return (
    <div className=" change-password">
      <div className="card border-0 rounded-0">
        <div className="card-head">change password</div>
        <div className="card-body">
          <div className="row mb-5">
            <div className="col-lg-2 ">
              <label
                htmlFor="old-password"
                className="my-1"
                style={{
                  color:
                    firstActive.oldPassword &&
                    passwordfild.oldPassword.length < 6
                      ? "red"
                      : "",
                }}
              >
                OLd password
              </label>
            </div>
            <div className="col-lg-6  text-start">
              <input
                style={{
                  borderColor:
                    firstActive.oldPassword &&
                    passwordfild.oldPassword.length < 6
                      ? "red"
                      : "",
                }}
                value={passwordfild.oldPassword}
                onChange={handelChange}
                onFocus={handelFocous}
                type="password"
                name="oldPassword"
                id="old-password"
                placeholder="OLd password"
              />
              <section className="massages">
                <p className="m-0">
                  {firstActive.oldPassword &&
                  passwordfild.oldPassword.length == 0
                    ? "Password Is Required"
                    : ""}
                </p>
                <p className="m-0">
                  {firstActive.oldPassword &&
                  passwordfild.oldPassword.length < 6 &&
                  passwordfild.oldPassword.length !== 0
                    ? "Password Must Be 6 Characters Long"
                    : ""}
                </p>
              </section>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-2 ">
              <label
                htmlFor="new-password"
                className="my-1"
                style={{
                  color:
                    firstActive.newPassword &&
                    passwordfild.newPassword.length < 6
                      ? "red"
                      : passwordfild.newPassword.length > 0 &&
                        validatePassword(passwordfild.newPassword) !== "valid"
                      ? "red"
                      : "",
                }}
              >
                new password
              </label>
            </div>
            {console.log(validatePassword(passwordfild.newPassword))}
            <div className="col-lg-6  text-start">
              <input
                style={{
                  borderColor:
                    firstActive.newPassword &&
                    passwordfild.newPassword.length < 6
                      ? "red"
                      : passwordfild.newPassword.length > 0 &&
                        validatePassword(passwordfild.newPassword) !== "valid"
                      ? "red"
                      : "",
                }}
                value={passwordfild.newPassword}
                onChange={handelChange}
                onFocus={handelFocous}
                name="newPassword"
                id="new-password"
                type="password"
                placeholder="new password"
              />
              <section className="massages">
                <p className="m-0">
                  {firstActive.newPassword &&
                  passwordfild.newPassword.length == 0
                    ? "password is required"
                    : ""}
                </p>
                <p className="m-0">
                  {firstActive.newPassword &&
                  passwordfild.newPassword.length < 6 &&
                  passwordfild.newPassword.length !== 0
                    ? "password mustbe 6 charcter long"
                    : ""}
                </p>
                <p className="m-0">
                  {firstActive.newPassword
                    ? validatePassword(passwordfild.newPassword) == "valid"
                      ? ""
                      : validatePassword(passwordfild.newPassword)
                    : ""}
                </p>
              </section>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-2 ">
              <label
                htmlFor="confirm-password"
                style={{
                  color:
                    firstActive.confirmPassword &&
                    passwordfild.confirmPassword.length < 6 &&
                    passwordfild.confirmPassword.length == 0
                      ? "red"
                      : firstActive.confirmPassword &&
                        passwordfild.confirmPassword !==
                          passwordfild.newPassword
                      ? "red"
                      : "",
                }}
                className="my-1"
              >
                confirm password
              </label>
            </div>
            <div className="col-lg-6  text-start">
              <input
                style={{
                  borderColor:
                    firstActive.confirmPassword &&
                    passwordfild.confirmPassword.length < 6 &&
                    passwordfild.confirmPassword.length == 0
                      ? "red"
                      : firstActive.confirmPassword &&
                        passwordfild.confirmPassword !==
                          passwordfild.newPassword
                      ? "red"
                      : "",
                }}
                value={passwordfild.confirmPassword}
                onChange={handelChange}
                onFocus={handelFocous}
                name="confirmPassword"
                id="confirm-password"
                type="password"
                placeholder="confirm password"
              />
              <section className="massages">
                <p className="m-0">
                  {firstActive.confirmPassword &&
                  passwordfild.confirmPassword.length == 0
                    ? "Password is Required"
                    : ""}
                </p>
                <p className="m-0">
                  {firstActive.confirmPassword &&
                  passwordfild.confirmPassword.length < 6 &&
                  passwordfild.confirmPassword.length !== 0
                    ? "Password Must Be 6 Characters Long"
                    : ""}
                </p>
                <p className="m-0">
                  {firstActive.confirmPassword
                    ? passwordfild.confirmPassword !== passwordfild.newPassword
                      ? "Password Should Be Same As New Password"
                      : ""
                    : ""}
                </p>
              </section>
            </div>
          </div>
        </div>

        <div className="footer  d-flex p-3">
          <button
            onClick={handelClear}
            className=" border-0  text-white px-2 py-1"
          >
            {
              // console.log(firstActive.newPassword == "" &&
              //   passwordfild.newPassword.length < 6
              // )
              // console.log(firstActive.newPassword == "" &&
              //   passwordfild.newPassword.length > 6 && validatePassword(passwordfild.newPassword)
              //   !== "valid"
              // )
            }
            clear
          </button>
          <button
            disabled={
              firstActive.oldPassword == "" ||
              firstActive.newPassword == "" ||
              passwordfild.newPassword.length < 6 ||
              validatePassword(passwordfild.newPassword) !== "valid" ||
              passwordfild.confirmPassword !== passwordfild.newPassword
            }
            onClick={handleChangePass}
            className="mx-3 border-0  text-white px-2 py-1"
          >
            change
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
