import arrow from "../../assest/arrow.svg";
import menu from "../../assest/image1.svg";
import left from "../../assest/image (1).svg";
import right from "../../assest/image (2).svg";
import rightStop from "../../assest/image (3).svg";
import leftStop from "../../assest/image.svg";
import FilterationComponents from "../rightdivisoncomponents/FilterationComponents";
import { useContext, useEffect, useState } from "react";
import context from "../context/createContext";
import { getUserprofitLoss } from "../dataBaseService/service";
import { Slide, toast } from "react-toastify";
import { convertISOToCustomDate } from "../toolFunction";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns'



const currentdate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Format YYYY-MM-DD}
}


const ProfitAndLoss = () => {
  const [showFilterOf, setShowFilterOf] = useState("");
  const { setIsModeldisplay, setMatchIdForProfitAndLoss } = useContext(context);
  const [sortBy, setSortBy] = useState("");
  const [up, setUp] = useState(true);
  const [show, setShow] = useState(10);
  const [pageNo, setpageNo] = useState(1);
  const token = sessionStorage.getItem("token");
  const [fromDate, setFromDate] = useState(currentdate);
  const [toDate, setToDate] = useState(currentdate);
  const [profiteAndLossData, setProfitAndLossData] = useState([]);
  const [totalPnL, setTotalPnL] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);





  function handelShow(e) {
    setShow(e.target.value);
  }


  const handelSortBy = (thed) => {
    if (sortBy == thed) {
      setUp(!up);
    } else {
      setSortBy(thed);
    }
  }


  const getData = async () => {
    try {
      const res = await getUserprofitLoss(token, show, fromDate, toDate, pageNo);
      console.log(res);
      if (res && res.status == 200) {
        setProfitAndLossData(res.data.results);
        setTotalData(res.data.totalCount)
        setTotalPnL(res.data.totalPnl);
      } else {
        toast.error(res.data.massage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Slide,
        });
      }
    } catch (error) { }
  };

  useEffect(() => {
    setTotalPage(Math.trunc(totalData / show + 1));
  }, [totalData, show]);


  useEffect(() => {
    getData();
  }, [pageNo, show]);


  const handelsearch = () => {
    getData()
  }

  const handelMenuClick = (event, clickIndex) => {
    event.stopPropagation(); // Prevents the event from bubbling to the <th> element
    setShowFilterOf(clickIndex);
  };


  const handelProfitLoss = (id) => {
    setMatchIdForProfitAndLoss(id);
    setIsModeldisplay("Client Profit And Loss");
  };

  const pageDown = () => {
    if (pageNo > 1) {
      setpageNo(pageNo - 1);
    }
  };


  const pageUp = () => {
    if (pageNo < totalPage) {
      setpageNo(pageNo + 1);
    }
  };


  const goToLastPage = () => {
    setpageNo(totalPage);
  };

  const goToFirstPage = () => {
    setpageNo(1);
  };


  const handelDateChange = (date, type) => {
    console.log(format(date, 'yyyy-MM-dd'), type);
    const formattedDate = format(date, 'yyyy-MM-dd');
    if (type == "fromdate") {
      setFromDate(formattedDate);
    } else if (type == "todate") {
      setToDate(formattedDate);
    }
  };
  // console.log(profiteAndLossData);

  return (
    <div className="profit-and-loss p-2">

      <div className="container text-start ">
        <div className="row ">
          <div className="col-md-3 border1 py-3">
            <h5>Total P&L:</h5>
            <input
              className="w-100"
              value={totalPnL}
              style={{ color: totalPnL < 0 ? "red" : "" }}
              type="text"
              disabled
            />
          </div>
          <div className="col-md-3 border1 py-3">
            <h5>From:</h5>

            <DatePicker calendarClassName="custom-calendar" wrapperClassName="w-100" className="w-100" placeholderText="Select a date" dateFormat="dd/MM/yyyy" selected={fromDate} onChange={(date) => handelDateChange(date, "fromdate")} />
          </div>
          <div className="col-md-3 border1 py-3">
            <h5>To:</h5>
            <DatePicker calendarClassName="custom-calendar" wrapperClassName="w-100" className="w-100" placeholderText="Select a date" dateFormat="dd/MM/yyyy" selected={toDate} onChange={(date) => handelDateChange(date, "todate")} />
          </div>
          <div className="col-md-3 mt-3 border1 d-flex align-items-end py-3 ">
            <button onClick={handelsearch} className="w-100">earch</button>
          </div>
        </div>
        <div className="row border1">
          <div className=" col-4 col-lg-6 border1  ">
            <div className="row border1">
              <div className="col-lg-4 border1  py-3">
                <h5>Show:</h5>
                <select
                  onChange={handelShow}
                  defaultValue={10}
                  className="w-100 p-1"
                  name="show"
                  id="show"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-8 col-lg-6 border1 py-3">
            <div className=" row d-flex align-items-end justify-content-end h-100  downloader">
              <div className="col-lg-6 border1 border1-info">
                <button>PDF</button>
                <button className="midle-btn">CSV</button>
                <button>Excel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content mt-3">
        {profiteAndLossData.length == 0 ? (
          <div className="text-center w-100 py-3 fs-5 ">
            there are no data to show
          </div>
        ) : (
          <table className="text-start ">
            <thead>
              <tr className="">
                <th onClick={() => handelSortBy(1)} className="">
                  <span className=" w-100 d-flex align-items justify-content-between">
                    <span>
                      App Sport <img class={sortBy == 1 ? (up ? "updown" : "") : ""}
                        src={arrow} alt="" />
                    </span>{" "}
                    <img
                      onClick={(e) => handelMenuClick(e, 1)}
                      src={menu}
                      alt=""
                      className="menu-icon"
                    />
                    <FilterationComponents
                      isShow={showFilterOf == 1 ? true : false}
                      setShowFilterOf={setShowFilterOf}
                    />
                  </span>
                </th>
                <th onClick={() => handelSortBy(2)} className="">
                  <span className=" w-100 d-flex align-items justify-content-between">
                    <span>
                      Market Name <img class={sortBy == 2 ? (up ? "updown" : "") : ""}
                        src={arrow} alt="" />
                    </span>{" "}
                    <img
                      onClick={(e) => handelMenuClick(e, 2)}
                      src={menu}
                      alt=""
                      className="menu-icon"
                    />
                    <FilterationComponents
                      isShow={showFilterOf == 2 ? true : false}
                      setShowFilterOf={setShowFilterOf}
                    />
                  </span>
                </th>
                <th onClick={() => handelSortBy(3)} className="">
                  <span className=" w-100 d-flex align-items justify-content-between">
                    <span>
                      Result <img class={sortBy == 3 ? (up ? "updown" : "") : ""}
                        src={arrow} alt="" />
                    </span>{" "}
                    <img
                      onClick={(e) => handelMenuClick(e, 3)}
                      src={menu}
                      alt=""
                      className="menu-icon"
                    />
                  </span>
                  <FilterationComponents
                    isShow={showFilterOf == 3 ? true : false}
                    setShowFilterOf={setShowFilterOf}
                  />
                </th>
                <th onClick={() => handelSortBy(4)} className="">
                  <span className=" w-100 d-flex align-items justify-content-between">
                    <span>
                      P&L <img class={sortBy == 4 ? (up ? "updown" : "") : ""}
                        src={arrow} alt="" />
                    </span>{" "}
                    <img
                      onClick={(e) => handelMenuClick(e, 4)}
                      src={menu}
                      alt=""
                      className="menu-icon"
                    />
                  </span>
                  <FilterationComponents
                    isShow={showFilterOf == 4 ? true : false}
                    setShowFilterOf={setShowFilterOf}
                  />
                </th>
                <th onClick={() => handelSortBy(5)} className="">
                  <span className=" w-100 d-flex align-items justify-content-between">
                    <span>
                      Started Date
                      <img class={sortBy == 5 ? (up ? "updown" : "") : ""}
                        src={arrow} alt="" />
                    </span>{" "}
                    <img
                      onClick={(e) => handelMenuClick(e, 5)}
                      src={menu}
                      alt=""
                      className="menu-icon"
                    />
                    <FilterationComponents
                      isShow={showFilterOf == 5 ? true : false}
                      setShowFilterOf={setShowFilterOf}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {profiteAndLossData.map((profitdata) => {
                return (
                  <tr>
                    <td>{profitdata.game}</td>
                    <td>
                      <span
                        onClick={() => handelProfitLoss(profitdata.matchId)}
                        className="text-primary profitLink"
                      >
                        {profitdata.game}/{profitdata.marketName}
                      </span>
                    </td>
                    <td> {profitdata.resultVeiw}</td>
                    <td className={profitdata.pnlTag == 'Loss' ? "text-danger" : "text-success"}>{profitdata.pnl}</td>
                    <td>{convertISOToCustomDate(profitdata.date)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className="footer  d-flex align-items-center justify-content-end ">
        <section className="me-2">
          {(pageNo - 1) * show + 1} to {Math.min(pageNo * show, totalData)} of{" "}
          {totalData}{" "}
        </section>
        <section>
          <button onClick={goToFirstPage}>
            <img src={leftStop} alt="" />
          </button>
          <button onClick={pageDown}>
            <img src={left} alt="" />
          </button>
          <span>
            page {pageNo} of {totalPage}
          </span>
          <button>
            <img onClick={pageUp} src={right} alt="" />
          </button>
          <button onClick={goToLastPage}>
            <img src={rightStop} alt="" />
          </button>
        </section>

        {/* <section className="me-2">1 to 5 of 5 </section>
        <section>
          <button>
            <img src={leftStop} alt="" />
          </button>
          <button onClick={pageDown}>
            <img src={left} alt="" />
          </button>
          <span>page 1 of 1</span>
          <button onClick={pageUp}>
            <img src={right} alt="" />
          </button>
          <button>
            <img src={rightStop} alt="" />
          </button>
        </section> */}
      </div>
    </div>
  );
};
export default ProfitAndLoss;
