import React, { useRef, useState } from 'react'
import { useClickAway } from 'react-use';

const FilterationComponents = ({ isShow, setShowFilterOf }) => {
  let uniqueId = Math.floor(Math.random() * 10000)
  const popupRef = useRef(null); // Reference to the popup div
  const [isSecondShow, setIsSecondshow] = useState(null)


  const handelChange = (e) => {
    // console.log(e.target.value);
    if (e.target.value == "") {
      setIsSecondshow(null)
    }
    else {
      setIsSecondshow(e.target.value)
    }
  }



  // Close the popup when clicking outside
  useClickAway(popupRef, (event) => {
    // Only close if the popup is shown
    if (isShow) {
      setShowFilterOf("");
    }
  });

  // Don't render the popup if it's not visible
  if (!isShow) return null;



  return (
    <div className='filter-component' ref={popupRef} style={{ display: isShow ? "block" : "none" }}>
      <div>
        <select name="" id="" className='w-100 px-1'>
          <option value="">Equal</option>
          <option value="">Not equal</option>
          <option value="">Starts with</option>
          <option value="">End with</option>
          <option value="">Not contains</option>
        </select>
      </div>
      <input type="text" onChange={handelChange} placeholder='filter...' />
      {isSecondShow ? <><div className='border1 d-flex my-2 '>
        <section className='d-flex align-items-center border1'><input className='m-0' type="radio" name={`and-or-${uniqueId}`} id={`and-${uniqueId}`} /><label className='m-0' htmlFor={`and-${uniqueId}`}><h5>AND</h5></label></section>
        <section className='d-flex align-items-center border1'><input className='m-0' type="radio" name={`and-or-${uniqueId}`} id={`or-${uniqueId}`} /><label className='m-0' htmlFor={`or-${uniqueId}`}><h5>OR</h5></label></section>
      </div>
        <div>
          <select name="" id="" className='w-100 px-1'>
            <option value="">Equal</option>
            <option value="">Not equal</option>
            <option value="">Starts with</option>
            <option value="">End with</option>
            <option value="">Not contains</option>
          </select>
        </div><input type="text" /></> : ""}


    </div>
  )
}

export default FilterationComponents;
