import React, { useContext, useEffect, useState } from "react";
import { getUserBetHistory } from "../dataBaseService/service";
import context from "../context/createContext";
import { convertISOToCustomDate } from "../toolFunction";

const ClientBetHistory = () => {
  const [hisToryData, setHistoryData] = useState([]);
  const token = sessionStorage.getItem("token");
  const { marketIdForHistory, isModelDisplay } = useContext(context);

  const getData = async () => {
    let res = await getUserBetHistory(token, marketIdForHistory);
    if (res && res.status == 200) {
      setHistoryData(res.data.betHistory);
    }
  };

  useEffect(() => {
    if (isModelDisplay == "Client Bet History") {
      getData();
    }
  }, [isModelDisplay]);

  console.log(hisToryData);

  return (
    <div className="client-bet-history">
      <table className="w-100">
        <thead>
          <tr>
            <th className="text-start">Ref.ID</th>
            <th className="text-start">Market</th>
            <th className="">Type</th>
            <th className="">Rate</th>
            <th className="">Stake</th>
            <th className="">P|L</th>
            <th className="">M.Date</th>
            <th className="text-end">DR</th>
            <th className="text-end">CR</th>
            <th className="text-end">Net</th>
          </tr>
        </thead>

        <tbody>
          {hisToryData.map((history, index) => {
            console.log(history);

            return (
              <tr key={index}>
                <td className="">{history.matchId}</td>
                <td className="">
                  {history.marketName}/{history.trade}/{history.marketName}
                </td>
                <td className="">{history.type}</td>
                <td className="">{history.odds}</td>
                <td className="">{history.stake}</td>
                <td className=""></td>
                <td className="">{convertISOToCustomDate(history.betDate)}</td>
                <td className=""></td>
                <td className="">-</td>
                <td className=""></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientBetHistory;
