import React from 'react'
import Lodar from "../../assest/winxbet_loader.svg"

const Loader = () => {
  return (
  <div className="loader w-100 h-100">
     <img src={Lodar}  />
  </div>
  )
}

export default Loader
