import { configureStore } from "@reduxjs/toolkit";
import betSlipSlice from "./betSlipSlice";


const store = configureStore({
    reducer: {
        betSlipData: betSlipSlice
    }
}
)

export default store;