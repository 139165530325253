import React, { useEffect, useRef, useState } from "react";

const Scores = ({ scoreBoxId }) => {

  const [refreshKey, setRefreshKey] = useState(0); // Key to trigger rerender

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1); // Update the key every 3 mins
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);



  return (
    <>
      {scoreBoxId.sportsName == "Cricket" ? (
        <iframe
          _ngcontent-c27=""
          allowFullScreen="true"
          id="widgetIframe1"
          marginheight="0"
          marginwidth="0"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          width="100%"
          src={`https://deployed.live/scoreboard?id=${scoreBoxId.version}`}
          class="ng-star-inserted"
        ></iframe>
      ) : scoreBoxId.sportsName == "Soccer" ||
        scoreBoxId.sportsName == "Tennis" ? (
        <iframe
          _ngcontent-c27=""
          allowFullScreen="true"
          id="widgetIframe1"
          marginheight="0"
          marginwidth="0"
          mozallowfullscreen="true"
          scrolling="auto"
          style={{ border: "none" }}
          webkitallowfullscreen="true"
          width="100%"
          src={`https://bfscore.cloudd.site/?id=${scoreBoxId.version}`}
          class="ng-star-inserted"
        ></iframe>
      ) : (
        ""
      )}
    </>
  );
};

export default Scores;
