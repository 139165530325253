import React, { memo } from 'react'
import flag from '../../assest/download.svg'
import FancyBetRow from '../rightdivisoncomponents/FancyBetRow';


const FancyBet = ({ boxData, eventANdmarketIdAndSport, insitialUserDetails }) => {




  return (
    <div className='fancy-bet'>
      <div className="fancy-bet-head">
        <h3 className='text-start'> Fancybet</h3>
      </div>
      <div className="game-head">
        <div className="row">
          <div className="col-6 border1 ">
          </div>
          <div className="col-6 col-lg-6 border1 ">
            <button className=''>NO(k)</button>
            <button className='' >YES(L)</button>
          </div>
        </div>
      </div>
      {boxData.map((item, index) => {
        return (<FancyBetRow key={index} insitialUserDetails={insitialUserDetails} eventANdmarketIdAndSport={eventANdmarketIdAndSport} fancydetails={item} />)
      })}


    </div>
  )
}

export default memo(FancyBet)
