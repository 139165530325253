import { createSlice } from '@reduxjs/toolkit'

const initialData = { team: "", selectionId: "", tradeOn: "", price: "", marketId: "", eventId: "", sportsName: "", trade: "" }
export const betSlipSlice = createSlice({
    name: 'betSlipData',
    initialState: initialData,
    reducers: {
        addBetSlip: (state, action) => {
            console.log(action.payload);
            return state = action.payload
        },
        clearBetSlip: (state) => {
            return state = initialData;
        }
    },
})

// Action creators are generated for each case reducer function
export const { clearBetSlip, addBetSlip } = betSlipSlice.actions

export default betSlipSlice.reducer