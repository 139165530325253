
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import { Slide, toast } from "react-toastify";
import BoxCard from "../rightdivisoncomponents/BoxCard";
import OddsMarketBox from "../rightdivisoncomponents/OddsMarketBox";
import FancyBet from "../rightdivisoncomponents/FancyBet";
import { baseUrl, getMarketInfo } from "../dataBaseService/service";
import OneClickBet from "../rightdivisoncomponents/OneClickBet";
import BetSlipBox from "../rightdivisoncomponents/BetSlipBox";
import LiveBoardcast from "../rightdivisoncomponents/LiveBoardcast";
import { clearBetSlip } from "../../redux/betSlipSlice";
import { useDispatch } from "react-redux";
import context from "../context/createContext";
import Scores from "../rightdivisoncomponents/Scores";
import MatchedBets from "../rightdivisoncomponents/MatchedBets";
import BookmakerBox from "../rightdivisoncomponents/BookmarkerBox";
import { convertISOToCustomDate } from "../toolFunction";

const socket = io(baseUrl, {
  transports: ["websocket", "polling", "flashsocket"],
});

const MarketPage = () => {
  const [marketDetails, setMarketDetails] = useState({});
  const [liveStream, setliveStream] = useState(null);
  const [scoreBoxId, setScoreBoardId] = useState({});
  const { SetBetSlipOpen, isMobile, setBetslipCalulation } = useContext(context);
  const [eventANdmarketIdAndSport, SetEventANdmarketIdAndSport] = useState(null);
  const [insitialUserDetails, setInsitialUserDetails] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(clearBetSlip());
    setBetslipCalulation("");
    return SetBetSlipOpen(false);
  }, [id]);

  const getMarketData = async () => {
    let token = sessionStorage.getItem("token");
    try {
      const res = await getMarketInfo(id, token);

      if (res) {
        setInsitialUserDetails(res);
        setliveStream(res.liveTv);
        setScoreBoardId({
          sportsName: res.sportsName,
          version: res.version,
        });
        let { version, marketId, sportsName } = res;
        SetEventANdmarketIdAndSport({ eventId: version, marketId, sportsName });
        console.log(res[0]);
      } else {
        toast.error("Error while fetching data");
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
      toast.error("Error while fetching data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
        transition: Slide,
      });
    }
  };

  useEffect(() => {
    socket.emit("join", id);

    const handleOddsUpdate = (data) => {
      setMarketDetails(data);
    };
    socket.on("odds", handleOddsUpdate);

    getMarketData();

    return () => {
      //socket.off("odds", handleOddsUpdate);
      socket.emit("leave", id);
    };
  }, [id]);
  // console.log(marketDetails);

  return (
    <>
      <div className="market-page">
        <div className="row">
          <div className="col-lg-8 border1 border1-warning">
            <BoxCard
              tittle={insitialUserDetails.marketName}
              time={convertISOToCustomDate(insitialUserDetails.marketStartTime)}
              icon={
                liveStream ? (
                  <i className="fa fa-television ng-star-inserted"></i>
                ) : (
                  ""
                )
              }
            >

              <Scores scoreBoxId={scoreBoxId} />

              {isMobile ? (
                <LiveBoardcast liveTv={liveStream}></LiveBoardcast>
              ) : (
                ""
              )}


              {marketDetails.runners && (
                <OddsMarketBox
                  totalMatched={marketDetails.totalMatched}
                  boxName={"Match odds"}
                  insitialUserDetails={insitialUserDetails}
                  eventANdmarketIdAndSport={eventANdmarketIdAndSport}
                  boxData={marketDetails.runners}
                  initial={true}
                />
              )}
              {marketDetails.bookMaker && (
                <BookmakerBox
                  boxName={"Bookmaker"}
                  insitialUserDetails={insitialUserDetails}
                  eventANdmarketIdAndSport={eventANdmarketIdAndSport}
                  boxData={marketDetails.bookMaker}
                />
              )}
              {marketDetails.fancy && (
                <FancyBet
                  insitialUserDetails={insitialUserDetails}
                  eventANdmarketIdAndSport={eventANdmarketIdAndSport}
                  boxData={marketDetails.fancy}
                />
              )}
            </BoxCard>
          </div>
          <div className="col-lg-4 border1 border1-danger">
            <OneClickBet></OneClickBet>
            {isMobile ? (
              ""
            ) : (
              <LiveBoardcast liveTv={liveStream}></LiveBoardcast>
            )}
            <BetSlipBox getMarketData={getMarketData} />
            <MatchedBets insitialUserDetails={insitialUserDetails} marketId={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPage;

