import React, { useContext, useEffect, useState } from "react";
import flag from "../../assest/download.svg";
import BookmarkerGameRow from "./BookmarkerGameRow";
import cancelBtn from "../../assest/x-button.svg";
import context from "../context/createContext";
import { memo } from "react";
import { convertISOToCustomDate } from "../toolFunction";

const BookmakerBox = ({ boxName, boxData, eventANdmarketIdAndSport, insitialUserDetails }) => {
  const { setIsModeldisplay } = useContext(context);
  let sortedData;
  if (boxData) {
    sortedData = boxData.sort((a, b) => a.sortPeriority - b.sortPeriority);
  }

  return (
    <div className="market-box">
      <div className="market-head">
        <div className="row border1">
          <div className="col-md-7 py-2 border1 d-flex align-items-center justify-content-between">
            <section className="border1">
              <img src={flag} alt="" />
              <span className="match-name">{boxName}</span>
              <span className="count">(23460.67)</span>
            </section>
            <section className="inplay mx-auto">
              {insitialUserDetails.inplay ? <><i className="fa fa-check"></i> In-Play</> : ""}
            </section>
          </div>
          <div className="col-md-5 py-2 d-flex flex-wrap align-items-center justify-content-between  justify-content-md-end">
            <section className="date me-md-5">
              <span>
                {" "}
                <i onClick={() => setIsModeldisplay("Rules")} className="fa fa-info-circle setInfo info"></i>
                <i className="fa fa-clock-o ms-1"></i>{" "}
              </span>
              <span className="mx-1">Time:</span>
              <span className="mx-1">{convertISOToCustomDate(insitialUserDetails.marketStartTime)}</span>
              {/* <span>14:24:24:877</span> */}
            </section>
            {/* {initial ? "" : <img className='cancel-btn ' src={cancelBtn} alt="" />} */}
          </div>
        </div>
      </div>
      <div className="game-head">
        <div className="row">
          <div className="col-6 border1 ">
            <div className="row">
              <div className="col-lg-6">
                <section className="stack-limit">
                  <span>Stake Limit:</span>
                  <span className="value ms-1"> 100-500000</span>
                </section>
              </div>
              <div className="col-lg-6">
                {" "}
                <section className="max-profit">
                  <span>Max Profit:</span>
                  <span className="value"> 2500000</span>
                </section>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-6 border1 ">
            <button className="">Back</button>
            <button className="">Lay</button>
          </div>
        </div>
      </div>
      {sortedData
        ? sortedData.map((bookmaker, index) => {
          return (
            <BookmarkerGameRow
              insitialUserDetails={insitialUserDetails}
              rowData={bookmaker}
              eventANdmarketIdAndSport={eventANdmarketIdAndSport}
              key={index}
            />
          );
        })
        : ""}
    </div>
  );
};

export default memo(BookmakerBox);
