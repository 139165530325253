import React, { useContext } from 'react'
import LOGO from '../../assest/powerbets-logo.png'
import { CiMenuKebab } from 'react-icons/ci';
import context from '../context/createContext';
import { Link } from 'react-router-dom';

const Header = () => {

  const { setOpenProfile } = useContext(context)
  const { setOpenMenu, profileButtonRef, setIsModeldisplay } = useContext(context)
  const profile = JSON.parse(sessionStorage.getItem('userDetails'));

  const handelProfile = () => {
    setOpenProfile((pre) => {
      return !pre
    })
    setOpenMenu(false)
  }

  const handelMenu = () => {
    console.log('handelmenu');
    setOpenMenu((pre) => {
      return !pre
    })
    setOpenProfile(false)
  }

  return (
    <div className="header     border1   border1-3   border1-warning">
      <div className="logo">
        <Link to={"/home"}><img src={LOGO} alt="" /></Link>
      </div>
      <div className="row    w-100 m-0 py-1 py-lg-4">
        <div className="col-md-8 order-3 order-md-2">
          {/* <div className="marquee-container   border11">
            <div className="marquee-text">
              <span>This is a continuous marquee effect with pure CSS. </span>
              <span>This is a continuous marquee effect with pure CSS. </span>
              <span>This is a continuous marquee effect with pure CSS. </span>
            </div>
          </div> */}
          <div class="marquee-container">
            <div class="marquee-wrapper">
              <div class="marquee-text">
                <span><i class="fa fa-circle me-3"></i>"Cricket/International Twenty20 Matches/Zimbabwe v Afghanistan-20 OVER RUN AFG T: 11-12-2024 17:15:50 To 17:18:44 beetween Rate [153,155] bets are deleted Rate bets voided due to technical issues" </span>
                <span><i class="fa fa-circle me-3"></i>"Cricket/Syed Mushtaq Ali Trophy/Andhra v Uttar Pradesh-20 OVER RUN AND T: 09-12-2024 17:53:42 To 17:53:57 beetween Rate [138,139] bets are deleted BETS WILL BE VOIDED DUE TO WRONG RATE" </span>
                <span><i class="fa fa-circle me-3"></i>"Cricket/Womens One Day Internationals/Australia Women v India Women-50 OVER RUN IND-W T: 05-12-2024 12:07:05 To 12:20:00 beetween Rate [145,147] bets are deleted AFTER WKT BETS WILL BE VOIDED"</span>
                <span><i class="fa fa-circle me-3"></i>"Cricket/Womens One Day Internationals/Australia Women v India Women-R GHOSH RUNS T: 05-12-2024 12:07:05 To 12:20:00 beetween Rate [31] bets are deleted AFTER WKT BETS WILL BE VOIDED"</span>
              </div>
              <div class="marquee-text">
                <span><i class="fa fa-circle me-3"></i>"Cricket/International Twenty20 Matches/Zimbabwe v Afghanistan-20 OVER RUN AFG T: 11-12-2024 17:15:50 To 17:18:44 beetween Rate [153,155] bets are deleted Rate bets voided due to technical issues" </span>
                <span><i class="fa fa-circle me-3"></i>"Cricket/Syed Mushtaq Ali Trophy/Andhra v Uttar Pradesh-20 OVER RUN AND T: 09-12-2024 17:53:42 To 17:53:57 beetween Rate [138,139] bets are deleted BETS WILL BE VOIDED DUE TO WRONG RATE" </span>
                <span><i class="fa fa-circle me-3"></i>"Cricket/Womens One Day Internationals/Australia Women v India Women-50 OVER RUN IND-W T: 05-12-2024 12:07:05 To 12:20:00 beetween Rate [145,147] bets are deleted AFTER WKT BETS WILL BE VOIDED"</span>
                <span><i class="fa fa-circle me-3"></i>"Cricket/Womens One Day Internationals/Australia Women v India Women-R GHOSH RUNS T: 05-12-2024 12:07:05 To 12:20:00 beetween Rate [31] bets are deleted AFTER WKT BETS WILL BE VOIDED"</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 order-2 order-md-3 ">
          <section className='d-flex info align-items-center   border1   border1-info justify-content-between w-100 '>
            <button className='dot-btn' onClick={handelMenu}> <i className="fa fa-ellipsis-v"></i></button>
            <div className='d-flex   border1 align-items-center ms-auto'>
              <section className='mx-5 mx-lg-3'>Balance: {profile ? profile.availableBalance : ''}</section>
              <section style={{ cursor: "pointer" }} className='mx-3' onClick={() => setIsModeldisplay("Liability")}>Liability: {profile ? profile.exposure : ''}</section>
              <button style={{ background: "none", border: "none" }} ref={profileButtonRef} onClick={handelProfile}><i className="fa fa-ellipsis-v"></i></button>
            </div>
          </section>
        </div>

      </div>
    </div>
  )
}

export default Header
