import React, { useContext } from "react";
import { memo } from "react";
import { useDispatch } from "react-redux";
import context from "../context/createContext";
import { addBetSlip } from "../../redux/betSlipSlice";

const BookmarkerGameRow = ({
  rowData,
  eventANdmarketIdAndSport,
  insitialUserDetails,
}) => {
  const { SetBetSlipOpen, handleScrollToBox, betslipCalulation } =
    useContext(context);
  const dispatch = useDispatch();
  const {
    game,
    eventId,
    marketId,
    trade,
    selectionId,
    type,
    rate,
    stake,
    final,
  } = betslipCalulation;

  const handelClick = (team, selectionId, tradeOn, price) => {
    const { eventId, marketId, sportsName } = eventANdmarketIdAndSport;
    SetBetSlipOpen(true);
    if ((team, selectionId, tradeOn, price, eventId, marketId, sportsName)) {
      dispatch(
        addBetSlip({
          team,
          selectionId,
          tradeOn,
          price,
          eventId,
          marketId,
          sportsName,
          trade: "bookmaker",
        })
      );
    }
    handleScrollToBox();
  };

  // const conditionRender = () => {

  //     if (type == "back") {

  //         if (selectionId == rowData.selectionId) {
  //             return (
  //                 <>

  //                     <i aria-hidden="true" className="fa fa-arrow-right font-weight-bold  text-success mx-2" />
  //                     <span className='text-success'>{finalNum}</span>
  //                 </>
  //             )
  //         }

  //         else {

  //             return (<><i aria-hidden="true" className="fa fa-arrow-right font-weight-bold  text-danger mx-2" />
  //                 <span className='text-danger'>{-stake}</span></>)
  //         }

  //     }
  //     else if (type == "lay") {

  //         if (selectionId == rowData.selectionId) {
  //             return (
  //                 <>

  //                     <i aria-hidden="true" className="fa fa-arrow-right font-weight-bold  text-success mx-2" />
  //                     <span className='text-success'>{stake}</span>
  //                 </>
  //             )
  //         }

  //         else {
  //             return (<><i aria-hidden="true" className="fa fa-arrow-right font-weight-bold  text-danger mx-2" />
  //                 <span className='text-danger'>{-finalNum}</span></>)
  //         }
  //     }

  // }

  const { bookMExposure } = insitialUserDetails;

  const conditionRender = (exposer) => {
    console.log(exposer);
    let num = (rate * stake) / 100;
    let finalNum = Number(num.toFixed(2));

    if (type == "back") {
      if (selectionId == rowData.selectionId) {
        return (
          <span
            className={`${finalNum + exposer > 0 ? "text-success" : "text-danger"
              }`}
          >
            <i
              aria-hidden="true"
              className="fa fa-arrow-right font-weight-bold   mx-2"
            />
            <span>{finalNum + exposer}</span>
          </span>
        );
      } else {
        return (
          <span
            className={`${exposer + -stake > 0 ? "text-success" : "text-danger"
              }`}
          >
            <i
              aria-hidden="true"
              className="fa fa-arrow-right font-weight-bold mx-2"
            />
            <span>{exposer + -stake}</span>
          </span>
        );
      }
    } else if (type == "lay") {
      if (selectionId == rowData.selectionId) {
        return (
          <span
            className={`${exposer + -finalNum > 0 ? "text-success" : "text-danger"
              }`}
          >
            <i
              aria-hidden="true"
              className="fa fa-arrow-right font-weight-bold mx-2"
            />
            <span> {exposer + -finalNum}</span>
          </span>
        );
      } else {
        return (
          <span
            className={`${exposer + stake > 0 ? "text-success" : "text-danger"
              }`}
          >
            <i
              aria-hidden="true"
              className={`fa fa-arrow-right font-weight-bold   mx-2`}
            />
            <span>{exposer + stake}</span>
          </span>
        );
      }
    }
  };


  return (
    <div className="game-row">
      <div className="row border1 ">
        <div className=" col-6 col-lg-6 border1 text-start ">
          <h1 className="ms-1">{rowData.selectionName}</h1>
          {/* <h5 className='ms-1'> {trade == "bookmaker" ? <><span className='text-danger'>0000.0</span>{conditionRender()}</> : ""}</h5> */}
          <h5 className="ms-1">
            {bookMExposure ? (
              Object.keys(bookMExposure).length !== 0 ? (
                <span
                  className={
                    bookMExposure[rowData.selectionId] > 0
                      ? "text-success"
                      : "text-danger"
                  }
                >
                  {bookMExposure[rowData.selectionId]}
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {trade == "bookmaker" ? (
              <>{conditionRender(bookMExposure[rowData.selectionId] || 0)}</>
            ) : (
              ""
            )}
          </h5>
        </div>
        <div className=" col-6  col-lg-6 border1 d-flex ">
          <div className="game-box ">
            <section className="price">{""}</section>
            <p className="volum">{""}</p>
          </div>
          <div className="game-box">
            <section className="price">{""}</section>
            <p className="volum">{""}</p>
          </div>
          <div
            className="game-box"
            onClick={() =>
              handelClick(
                rowData.selectionName,
                rowData.selectionId,
                "back",
                rowData.backOdds
              )
            }
          >
            <section className="price p-2">{rowData.backOdds}</section>
            <p className="volum">{""}</p>
          </div>
          <div
            className="game-box"
            onClick={() =>
              handelClick(
                rowData.selectionName,
                rowData.selectionId,
                "lay",
                rowData.layOdds
              )
            }
          >
            <section className="price p-2">{rowData.layOdds}</section>
            <p className="volum">{""}</p>
          </div>
          <div className="game-box">
            <section className="price">{""}</section>
            <p className="volum">{""}</p>
          </div>
          <div className="game-box">
            <section className="price">{""}</section>
            <p className="volum">{""}</p>
          </div>
          <div
            className={`deactive-box  ${rowData.statusName == "ONLINE"
              ? rowData.backOdds == 0 || rowData.layOdds == 0
                ? "d-flex"
                : "d-none"
              : "d-flex"
              }`}
          >
            {rowData.backOdds == 0 || rowData.layOdds == 0
              ? ""
              : rowData.statusName == "SUSPEND"
                ? "market Suspended"
                : rowData.statusName}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BookmarkerGameRow);
