import React, { useContext, useEffect, useState } from 'react';
import { getLaderDetails } from '../dataBaseService/service';
import context from '../context/createContext';


const BookPosition = () => {
    const [booksposition, setBookPosition] = useState([])
    const { isModelDisplay, LadderId } = useContext(context)
    const token = sessionStorage.getItem('token')


    useEffect(() => {
        const getData = async () => {
            let res = await getLaderDetails(token, LadderId);
            console.log(res);

            if (res.status == 200) {
                let array = []
                for (const [key, value] of Object.entries(res.data)) {
                    console.log(key, value);
                    array.push({ run: key, Pl: value })
                }

                setBookPosition(array)

            }

        }
        if (isModelDisplay == "Book Position") {
            getData()
            console.log(LadderId);

        }
    }, [isModelDisplay])

    return (
        <div className='bookPosition'>
            <table className='w-100'>
                <thead>
                    <tr>
                        <td className='border'>Runs</td>
                        <td className='border'>P / L</td>
                    </tr>
                </thead>
                <tbody>
                    {booksposition.map((ele) => {
                        return (<tr>
                            <td className='border'>{ele.run}</td>
                            <td className={`border ${ele.Pl > 0 ? "text-success" : "text-danger"}`}>{ele.Pl}</td>
                        </tr>)
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default BookPosition
