
export function convertISOToCustomDate(isoDate) {
    let date = new Date(isoDate);

    // Extract date components
    let day = String(date.getUTCDate()).padStart(2, '0');
    let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year = date.getUTCFullYear();

    // Extract time components
    let hours = date.getUTCHours();
    let minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Convert to 12-hour format with AM/PM
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 0 (midnight) to 12

    // Format and return the date
    return `${day}-${month}-${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
}


// Cache object to store previously computed results
const formatCache = {};

// Optimized format function
export function formatNumber(value) {
    // Return from cache if available
    if (formatCache[value]) {
        return formatCache[value];
    }

    // Convert the input value to a number
    const num = parseFloat(value);
    if (isNaN(num)) {
        return 'Invalid number';
    }

    let formattedValue;
    if (Math.abs(num) >= 1_000_000_000) {
        formattedValue = (num / 1_000_000_000).toFixed(3) + 'billion';
    } else if (Math.abs(num) >= 1_000_000) {
        formattedValue = (num / 1_000_000).toFixed(3) + 'million';
    } else if (Math.abs(num) >= 1_000) {
        formattedValue = (num / 1_000).toFixed(1) + 'k';
    } else {
        formattedValue = num.toString();
    }

    // Store the result in cache
    formatCache[value] = formattedValue;

    return formattedValue;
}

