import React, { memo, useContext, useEffect } from "react";
import flag from "../../assest/download.svg";
import cancelBtn from "../../assest/x-button.svg";
import context from "../context/createContext";
import { addBetSlip } from "../../redux/betSlipSlice";
import { useDispatch } from "react-redux";
import { BsLadder } from "react-icons/bs";

const FancyBetRow = ({
  fancydetails,
  eventANdmarketIdAndSport,
  insitialUserDetails,
}) => {
  const {
    SetBetSlipOpen,
    setIsModeldisplay,
    handleScrollToBox,
    betslipCalulation,
    setLadderId
  } = useContext(context);
  const dispatch = useDispatch();

  const {
    game,
    eventId,
    marketId,
    trade,
    selectionId,
    type,
    rate,
    stake,
    final,
  } = betslipCalulation;

  const handelcancel = () => {
    console.log("cancel Request");
  };

  const handelClick = (team, selectionId, tradeOn, price, Runs) => {
    const { eventId, marketId, sportsName } = eventANdmarketIdAndSport;

    SetBetSlipOpen(true);

    if ((team, selectionId, tradeOn, price)) {
      dispatch(
        addBetSlip({
          team,
          selectionId,
          tradeOn,
          price,
          eventId,
          marketId,
          sportsName,
          trade: "fancy",
          Runs: Runs,
        })
      );
    }

    handleScrollToBox();
  };

  useEffect(() => {
    console.log(betslipCalulation);
  }, [betslipCalulation]);

  const { fancyExposure } = insitialUserDetails;

  // let number = (rate - 1) * stake
  // let finalNum = Number(number.toFixed(2))

  const getFinalalue = (exposer) => {
    let value = exposer + -final;

    return Number(value.toFixed(2));
  };

  const viewLadder = (id) => {
    setLadderId(id)
    setIsModeldisplay("Book Position")
  };

  return (
    <div className="fancy-bet-row">
      <div className="row border1 ">
        <div className=" col-6  border1 text-start d-flex align-items-center  justify-content-between">
          <div className="">
            <span className="d-flex align-items-center">
              <img
                style={{ width: "14px", height: "auto", marginRight: "7px" }}
                src={flag}
                alt=""
              />{" "}
              <h3 className="m-0">{fancydetails.marketName}</h3>
            </span>
            <h5 className="ms-1 m-0 text-danger align-middle">
              {fancyExposure ? (
                Object.keys(fancyExposure).length !== 0 ? (
                  <span
                    className={
                      fancyExposure[fancydetails.marketId] > 0
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    {fancyExposure[fancydetails.marketId]}
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <span>
                {" "}
                {selectionId == fancydetails.marketId ? (
                  trade == "fancy" ? (
                    <>
                      {" "}
                      <i
                        aria-hidden="true"
                        className="fa fa-arrow-right font-weight-bold mx-2"
                      />
                      {getFinalalue(fancyExposure[fancydetails.marketId] || 0)}{" "}
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </span>
            </h5>
            {/* <h5 className='ms-4'>{trade == "fancy" ? marketId == fancydetails.marketId ? final : "" : ""}</h5> */}
          </div>
          {/* <img className='d-blck d-md-none' style={{ width: "14px", height: "auto", marginRight: "7px" }} onClick={handelcancel} src={cancelBtn} alt="" /> */}
          {fancyExposure ? fancyExposure[fancydetails.marketId] ? <BsLadder className="fs-3" style={{ cursor: "pointer" }} onClick={() => viewLadder(fancydetails.marketId)} /> : "" : ""}
        </div>
        <div className=" col-6   col-lg-6 border1 d-flex ">
          <div style={{ width: "34%" }} className="border1 d-none d-md-block">
            <section className="stack-limit">
              <span>Stake Limit:</span>
              <span className="value ms-1">100-500000</span>
            </section>
            <section className="max-profit">
              <span>Max Profit:</span>
              <span className="value">500000</span>
            </section>
          </div>

          <div
            onClick={() =>
              handelClick(
                fancydetails.marketName,
                fancydetails.marketId,
                "no",
                fancydetails.oddsNo,
                fancydetails.runsNo
              )
            }
            className={`game-box ${fancydetails.statusName == "active" ? "" : ""
              }p-0`}
          >
            <section className="price p-2">{fancydetails.runsNo}</section>
            <p className="sessionpoint">[ {fancydetails.oddsNo} ]</p>
            {/* <div className={`deactive-box  ${fancydetails.statusName == "active" ? "d-block" : "d-block"}`}>{fancydetails.statusName}</div> */}
          </div>
          <div
            onClick={() =>
              handelClick(
                fancydetails.marketName,
                fancydetails.marketId,
                "yes",
                fancydetails.oddsYes,
                fancydetails.runsYes
              )
            }
            className={`game-box ${fancydetails.statusName == "active" ? "" : ""
              } p-0`}
          >
            <section className="price p-2">{fancydetails.runsYes}</section>
            <p className="sessionpoint">[ {fancydetails.oddsYes} ]</p>
            {/* <div className={`deactive-box  ${fancydetails.statusName == "active" ? "d-block" : "d-block"}`}>{fancydetails.statusName}</div> */}
          </div>

          <div className="game-box d-none d-md-block">
            <i
              onClick={() => setIsModeldisplay("Terms and Condition")}
              className="fa fa-info-circle setInfo"
            ></i>
          </div>
          <div className="game-box d-none d-md-block">
            {/* <img src={cancelBtn} alt="" onClick={handelcancel} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FancyBetRow);
