import React, { useState } from "react";
import Logo from "../../assest/powerbets-logo.png";
import { Bounce, toast } from "react-toastify";
import { loginUser } from "../authservice/service";
import { json, redirect, replace, useNavigate } from "react-router-dom";

const LoginPage = ({ setToken }) => {
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handelChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const onsubmit = async () => {
    if (loginData.username == "") {
      toast.error("Enter Your User-Name First", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } else if (loginData.password.length < 6) {
      toast.error("Password Should Be 6 Characters Long", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    } else {
      console.log(loginData);
      let res = await loginUser(loginData);
      console.log(res);

      if (res.status == 200) {
        if (res.data.userDetails.role !== "USER") {
          window.location = `http://192.168.1.51:3001/auth/${res.data.token}`;
        }
        else {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem(
            "userDetails",
            JSON.stringify(res.data.userDetails)
          );
          setToken(res.data.token); // Notify React of the change
          navigate("/");
        }

      } else {
        toast.error(res.response.data.message);
      }
    }
  };

  return (
    <div className=" ">
      <div className="bg-canvas"></div>
      <div className="container-md cardAnimate ">
        <div className="row card-content">
          <p className="login-box-msg">Sign in to start your session</p>
          <div className="col-md-6 left-login-body">
            <div className="login-box-body">
              <div className="login-logo">
                <img className="img-responsive" alt="" src={Logo} />
              </div>

              <form>
                <div>
                  <div className="input-icons">
                    <div className="input-field">
                      <i className="fa fa-user icon"></i>
                      <input
                        name="username"
                        onChange={handelChange}
                        type="text"
                        placeholder="user"
                        autoComplete="none"
                      />
                    </div>

                    <div className="input-field">
                      <i className="fa fa-lock icon"></i>
                      <input
                        name="password"
                        onChange={handelChange}
                        type="password"
                        placeholder="password"
                        autoComplete="none"
                      />
                    </div>
                  </div>
                </div>
                <div style={{ Height: "100px" }} className="row g-0 pt-2">
                  <div className="form-check col-xs-6  text-start py-2">
                    <input
                      className="form-check-input   mx-3"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label
                      className="form-check-label  "
                      htmlFor="defaultCheck1"
                    >
                      Remember Me
                    </label>
                  </div>
                  <div className="col-xs-6 border-0">
                    <a href="#" className="border-0"></a>
                  </div>
                </div>
              </form>

              <button className="buttonStyle fs-4 text-nowrap" onClick={onsubmit}>
                Sign In
              </button>
            </div>
            <div className="login-footer">
              <h4>Powered by</h4>
              <div className="clearfix"></div>
              <small className="fs-5">
                Gambling can be addictive, please play responsibly.
              </small>
              <p className="">
                Licensed &amp; Regulated by the Gambling Commission for
                customers in GB
              </p>
            </div>
          </div>
          <div className="col-md-6 right-login-bg hidden-xs"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
