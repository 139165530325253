import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useContext, useEffect, useState } from 'react';
import context from './components/context/createContext';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './components/pages/LoginPage';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedLayout from './components/layout/ProtectedLayout';
import { validateToken } from './components/authservice/tokenalidation';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';


function App() {
  const { loading, openMenu } = useContext(context)
  const [token, setToken] = useState(sessionStorage.getItem('token'));

  //console.log(token);


  return (
    <div className="App border1 border1-5 w-100">
      <Routes>
        <Route path='/login' element={<LoginPage setToken={setToken} />} />
        <Route path='*' element={token && validateToken(token) ? <ProtectedLayout setToken={setToken} /> : <Navigate to="/login" replace />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;



