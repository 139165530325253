import { useContext, useEffect, useState } from "react";
import { GetUserLiability } from "../dataBaseService/service";
import context from "../context/createContext";
import { toast } from "react-toastify";
import Loader from "../pages/Loader";
import { Link } from "react-router-dom";

const Lability = () => {
  const [allLability, setAllLability] = useState([]);
  const { isModelDisplay, setIsModeldisplay } = useContext(context);
  const [loading, setLoading] = useState(false);
  let token = sessionStorage.getItem("token");

  const getData = async () => {
    try {
      setLoading(true);
      let res = await GetUserLiability(token);
      console.log(res);

      if (res && res.status == 200) {
        setAllLability(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error While Fatching Data ");
      }

      console.log(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error While Fatching Data " + error);
    }
  };

  useEffect(() => {
    if (isModelDisplay == "Liability") {
      console.log("ok");

      getData();
    }
  }, [isModelDisplay]);
  console.log(allLability);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="lability">
          <table className="w-100 text-start">
            <thead className=" ">
              <tr>
                <th className="border">Event Type</th>
                <th className="border">Event Name</th>
                <th className="border">Match Name</th>
                <th className="border">Trade</th>
              </tr>
            </thead>
            <tbody>
              {allLability.map((betdetails, index) => {
                console.log(betdetails);

                return (
                  <tr key={index}>
                    <td className="border">{betdetails.game}</td>
                    <td className="border text-primary">
                      <Link onClick={() => setIsModeldisplay("")} to={`market/${betdetails.matchId}`}>{betdetails.marketName}</Link>
                    </td>
                    <td className="border text-uppercase">
                      {betdetails.trade}
                    </td>
                    <td className="border">{betdetails.count}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Lability;
