import React, { useContext } from "react";
import context from "../context/createContext";

const ClientProfitAndLoss = () => {
  const { matchIdForProfitAndLoss } = useContext(context);

  return (
    <div className="client-profit-loss">
      {matchIdForProfitAndLoss}

      <table className="w-100 border">
        <thead>
          <tr>
            <th className="border">Selection</th>
            <th className="border">Odds</th>
            <th className="border">Stack</th>
            <th className="border">Type</th>
            <th className="border">Placed</th>
            <th className="border">Profit/Loss</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border">Northern Warriors</td>
            <td className="border">1.82</td>
            <td className="border">100	</td>
            <td className="border">Back</td>
            <td className="border" >11/26/2024 7:46:18 PM	</td>
            <td className="border">68</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td className="text-start border" colSpan={4}>
              <section className="mx-5">Average Odds :</section>
              <section className="mx-5"><input id="on" name="isON" type="radio" />
                <label htmlFor="on" className="mx-3">On</label>
                <input name="isON" checked id="off" type="radio" />
                <label htmlFor="off" className="mx-3">Off</label>
              </section>
            </td>
            <td className="border">
              <section>Back Total</section>
              <section>Lay Total</section>
              <section>Market Total</section>
              <section>Commission</section>
              <section>Total</section>
            </td>
            <td>
              <section>68</section>
              <section>00</section>
              <section>1.34</section>
              <section>123</section>
              <section>123</section>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ClientProfitAndLoss;
