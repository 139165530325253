import React, { useEffect, useState } from 'react'
import { FaTrophy } from "react-icons/fa6";
import MatchRow from './MatchRow';



const SportsCardList = ({ match }) => {
  const { markets, id, sportsName } = match;


  // console.log(markets);



  return (
    <div className='sports-card  shadow my-3' >
      <div className="upper-header border1 text-start px-2">
        <FaTrophy className='me-2' /><span>{sportsName}</span>
      </div>
      <div className="content d-block bg-white">
        <div className="content-header border1  justify-content-end border1-info p-2 ">
          <div className="header-section w-50 d-flex justify-content-around border1 border1-warning">
            <section>1</section>
            <section>x</section>
            <section>2</section>
          </div>

        </div>
        {markets.map((marketdeatils, index) => {
          return (
            <MatchRow key={index} marketdeatils={marketdeatils} />
          )
        })}

      </div>
    </div>
  )
}

export default SportsCardList
