import { useEffect, useRef, useState } from "react";
import context from "./createContext";
import { getAllSports } from "../dataBaseService/service";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { clearBetSlip } from "../../redux/betSlipSlice";

const ContextProvider = ({ children }) => {
  const [openProfile, setOpenProfile] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isModelDisplay, setIsModeldisplay] = useState(false);
  const profileButtonRef = useRef(null);
  const [allsports, setAllSports] = useState([]);
  const [inplayMode, setInplayMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [betSlipOpen, SetBetSlipOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.screen.width < 770);
  const [betslipCalulation, setBetslipCalulation] = useState("");
  const rightBoxRef = useRef(null);
  const [marketIdForHistory, setMarketIdForHistory] = useState("");
  const [matchIdForProfitAndLoss, setMatchIdForProfitAndLoss] = useState("");
  const dispatch = useDispatch();
  const [isShowTv, setIsShowTv] = useState(false);
  const [LadderId, setLadderId] = useState("")

  const handellogOut = () => {
    setOpenProfile(false);
  };
  const handleScrollToBox = () => {
    if (rightBoxRef.current) {
      rightBoxRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getData = async () => {
    let data = await getAllSports();
    // console.log(data);

    if (data) {
      setAllSports(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const setMarketToLocal = (marketId) => {
    localStorage.setItem("selectedMarket", marketId);
  };

  // const betSlipOfAfter15s = () => {
  //   if (betSlipOpen) {
  //     timer = setTimeout(() => {
  //       SetBetSlipOpen(false);
  //       setBetslipCalulation("");
  //       dispatch(clearBetSlip());
  //     }, 15000);
  //   }
  // };

  // useEffect(() => {
  //   console.log("okokokoko");

  //   betSlipOfAfter15s();
  // }, [betSlipOpen]);

  // console.log(betslipCalulation);

  return (
    <context.Provider
      value={{
        LadderId,
        setLadderId,
        isShowTv,
        setIsShowTv,
        matchIdForProfitAndLoss,
        setMatchIdForProfitAndLoss,
        marketIdForHistory,
        setMarketIdForHistory,
        rightBoxRef,
        handleScrollToBox,
        betslipCalulation,
        setBetslipCalulation,
        isMobile,
        betSlipOpen,
        SetBetSlipOpen,
        loading,
        setLoading,
        inplayMode,
        setInplayMode,
        allsports,
        openProfile,
        setOpenProfile,
        openMenu,
        setOpenMenu,
        isModelDisplay,
        setIsModeldisplay,
        profileButtonRef,
        setMarketToLocal,
        handellogOut,
      }}
    >
      {children}
    </context.Provider>
  );
};
export default ContextProvider;
