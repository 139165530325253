import React, { useContext } from 'react'
import { memo } from 'react';
import { formatNumber } from '../toolFunction';
import context from '../context/createContext';
import { useDispatch } from 'react-redux';
import { addBetSlip } from '../../redux/betSlipSlice'



const OddsGameRow = ({ rowData, eventANdmarketIdAndSport, insitialUserDetails }) => {
    //console.log(rowData);
    const { SetBetSlipOpen, betslipCalulation, handleScrollToBox } = useContext(context)

    const dispatch = useDispatch()
    const { game, eventId, marketId, trade, selectionId, type, rate, stake, final } = betslipCalulation;
    // console.log(betslipCalulation);
    const handelClick = (team, selectionId, tradeOn, price) => {
        const { eventId, marketId, sportsName } = eventANdmarketIdAndSport;

        SetBetSlipOpen(true)

        if (team, selectionId, tradeOn, price) {
            dispatch(addBetSlip({ team, selectionId, tradeOn, price, eventId, marketId, sportsName, trade: "odds" }))
        }
        handleScrollToBox()


    }

    const { oddsExposure } = insitialUserDetails;









    const conditionRender = (exposer) => {
        let number = (rate - 1) * stake
        let finalNum = Number(number.toFixed(2))

        if (type == "back") {

            if (selectionId == rowData.selectionId) {
                return (

                    <span className={`${finalNum + exposer > 0 ? "text-success" : "text-danger"}`}>
                        <i aria-hidden="true" className="fa fa-arrow-right font-weight-bold   mx-2" />
                        <span >{(finalNum + exposer).toFixed(2)}</span>
                    </span>
                )
            }

            else {

                return (<span className={`${exposer + (-stake) > 0 ? "text-success" : "text-danger"}`}><i aria-hidden="true" className="fa fa-arrow-right font-weight-bold mx-2" />
                    <span >{(exposer + (-stake)).toFixed(2)}</span></span>)
            }

        }
        else if (type == "lay") {



            if (selectionId == rowData.selectionId) {
                return (

                    <span className={`${exposer + (-finalNum) > 0 ? "text-success" : "text-danger"}`}>
                        <i aria-hidden="true" className="fa fa-arrow-right font-weight-bold mx-2" />
                        <span > {(exposer + (-finalNum)).toFixed(2)}</span>
                    </span>
                )
            }

            else {
                return (<span className={`${exposer + stake > 0 ? "text-success" : "text-danger"}`}><i aria-hidden="true"
                    className={`fa fa-arrow-right font-weight-bold   mx-2`} />
                    <span >{(exposer + stake).toFixed(2)}</span></span>)
            }
        }

    }


    return (
        <div className="game-row">
            <div className="row border1 ">
                <div className=" col-6 col-lg-6 border1 text-start py-2">
                    <h1 className='ms-1'>{rowData.runnerName}</h1>
                    <h5 className='ms-1'>{oddsExposure ? Object.keys(oddsExposure).length !== 0 ? <span className={oddsExposure[rowData.selectionId] > 0 ? "text-success" : 'text-danger'}>{oddsExposure[rowData.selectionId]}</span> : "" : ""}
                        {trade == "odds" ? <>{conditionRender(oddsExposure[rowData.selectionId] || 0)}</> : ""}
                    </h5>
                </div>
                <div className=" col-6  col-lg-6 border1 d-flex ">

                    <div onClick={() => handelClick(rowData.runnerName, rowData.selectionId, 'back', rowData.ex.availableToBack[2].price)} className="game-box ">
                        <section className='price'>{rowData.ex.availableToBack.length > 0 ? rowData.ex.availableToBack[2].price : ""}</section>
                        <p className="volum">{formatNumber(rowData.ex.availableToBack.length > 0 ? rowData.ex.availableToBack[2].size : "")}</p>
                    </div>
                    <div onClick={() => handelClick(rowData.runnerName, rowData.selectionId, 'back', rowData.ex.availableToBack[1].price)} className="game-box">

                        <section className='price'>{rowData.ex.availableToBack.length > 0 ? rowData.ex.availableToBack[1].price : ""}</section>

                        <p className="volum">{rowData.ex.availableToBack.length > 0 ? formatNumber(rowData.ex.availableToBack[1].size) : ""}</p>

                    </div><div onClick={() => handelClick(rowData.runnerName, rowData.selectionId, 'back', rowData.ex.availableToBack[0].price)} className="game-box">

                        <section className='price'>{rowData.ex.availableToBack.length > 0 ? rowData.ex.availableToBack[0].price : ""}</section>

                        <p className="volum">{rowData.ex.availableToBack.length > 0 ? formatNumber(rowData.ex.availableToBack[0].size) : ""}</p>

                    </div><div onClick={() => handelClick(rowData.runnerName, rowData.selectionId, 'lay', rowData.ex.availableToLay[0].price)} className="game-box">

                        <section className='price'>{rowData.ex.availableToLay.length > 0 ? rowData.ex.availableToLay[0].price : ""}</section>

                        <p className="volum">{rowData.ex.availableToLay.length > 0 ? formatNumber(rowData.ex.availableToLay[0].size) : ""}</p>

                    </div><div onClick={() => handelClick(rowData.runnerName, rowData.selectionId, 'lay', rowData.ex.availableToLay[1].price)} className="game-box">

                        <section className='price'>{rowData.ex.availableToLay.length > 0 ? rowData.ex.availableToLay[1].price : ""}</section>

                        <p className="volum">{rowData.ex.availableToLay.length > 0 ? formatNumber(rowData.ex.availableToLay[1].size) : ""}</p>

                    </div><div onClick={() => handelClick(rowData.runnerName, rowData.selectionId, 'lay', rowData.ex.availableToLay[2].price)} className="game-box">

                        <section className='price'>{rowData.ex.availableToLay.length > 0 ? rowData.ex.availableToLay[2].price : ""}</section>

                        <p className="volum">{rowData.ex.availableToLay.length > 0 ? formatNumber(rowData.ex.availableToLay[2].size) : ""}</p>

                    </div>
                    <div className={`deactive-box  ${rowData.status == "ACTIVE" ? "d-none" : ""}`}>{rowData.status}</div>
                </div>
            </div>
        </div>
    )
}

export default memo(OddsGameRow)
