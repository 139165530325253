import React, { useContext, useState } from 'react'
import context from '../context/createContext'

const OneClickBet = () => {
    const [checkActive,setCheckActive] = useState(false)
    const {setIsModeldisplay} = useContext(context) 
    const handelcheckChange = ()=>{
        setCheckActive(!checkActive)
    }

  return (
    <div className="one-click-bet mb-3">
                    <input type="checkbox" id="one-click-checkbox" className="d-none" onChange={handelcheckChange}/>
                    <label style={{cursor:"pointer"}} htmlFor="one-click-checkbox" className="d-flex align-items-center m-0"><div className="fakebox me-2">
                        <i className={`cr-icon fa fa-check ${checkActive?"checkactive":"checkdeactive"}` }></i>
                    </div>One Click Bet</label>
                    {checkActive?<><span className="mx-2">(100)-</span>
                        <span onClick={()=>setIsModeldisplay("One Click Bet Stake")} className="edite-btn ">Edit</span></>:""}
                </div>
  )
}

export default OneClickBet
