import axios from "axios"
import { baseUrl } from "../dataBaseService/service";

export const loginUser = async (userdata) => {
    let data = JSON.stringify(userdata)
    console.log(data);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}user/login`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }

    try {
        return await axios.request(config)
        console.log("login call");

    } catch (error) {

        return error
    }
}

export const VeryfiUser = async (token) => {
    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}user/authVerify`,
        headers: { token: token }
    }
    try {
        return await axios.request(config)

    }
    catch (err) {
        console.log(err);

    }
}