import React, { memo, useContext, useEffect, useState } from "react";
import SportsCardList from "../rightdivisoncomponents/SportsCardList";
import img from "../../assest/theator.svg";
import context from "../context/createContext";
import { getAllGames, getAllInplayGames } from "../dataBaseService/service";
import { toast } from "react-toastify";

const HomePage = () => {
  const [active, setActive] = useState("all");
  const [showPosterContent, setShowPosterContent] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const { inplayMode, setLoading } = useContext(context);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (inplayMode) {
        let data = await getAllInplayGames();
        console.log(data);

        if (data) {
          setAllGames(data);
          setLoading(false);
        } else {
          toast.error("error while get matches");
          setLoading(false);
        }
      } else {
        let data = await getAllGames();
        // console.log(data);

        if (data) {
          setAllGames(data);
        } else {
          toast.error("error while get matches");
        }
      }
      setLoading(false);
    };

    getData();
  }, [inplayMode]);

  return (
    <div className="home-page">
      <div className="poster my-2">
        <div className="poster-body py-4">
          <section
            onClick={() => setShowPosterContent(!showPosterContent)}
            className="poster-btn m-0 "
          >
            {" "}
            <span className="blink">click now</span> live casino games{" "}
            <span className="blink">click now</span>
          </section>
        </div>
        <div
          className="poster-content border bg-white"
          style={{ display: showPosterContent ? "block" : "none" }}
        >
          <div>
            <ul className="d-flex list-unstyled">
              <li
                onClick={() => setActive("all")}
                className={active == "all" ? "active" : ""}
              >
                All
              </li>
              <li
                onClick={() => setActive("Live Casino Games")}
                className={active == "Live Casino Games" ? "active" : ""}
              >
                Live Casino Games
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="content">
        {allGames.map((match, index) => {
          return <SportsCardList key={index} match={match} />;
        })}
      </div>
    </div>
  );
};

export default memo(HomePage);
