import React, { useContext, useRef } from "react";
import context from "../context/createContext";
import { Link, useNavigate } from "react-router-dom";
import { useClickAway } from "react-use";

const UserProfile = ({ setToken }) => {
  const {
    openProfile,
    setOpenProfile,
    profileButtonRef,
    setIsModeldisplay,
    handellogOut,
  } = useContext(context);
  const profile = JSON.parse(sessionStorage.getItem("userDetails"));
  const profileRef = useRef(null);
  const navigate = useNavigate(); // Hook to programmatically navigate

  useClickAway(profileRef, (event) => {
    if (
      profileButtonRef.current &&
      profileButtonRef.current.contains(event.target)
    ) {
      return; // Ignore clicks on the button
    }
    setOpenProfile(false);
  });

  return (
    <div
      ref={profileRef}
      className={`user-profile   h-100 ${openProfile ? "active-profile" : ""}`}
    >
      <div className="username">welcome, {profile ? profile.username : ""}</div>
      <ul className="list-unstyled p-0">
        <Link to={"/account-statement"} className="options">
          <li onClick={() => setOpenProfile(false)}>
            <i className="menu-icon fa  fa-bar-chart bg-red"></i>Account
            Statement
          </li>
        </Link>
        <Link to={"/profit-and-loss"} className="options">
          <li onClick={() => setOpenProfile(false)}>
            <i className="menu-icon fa fa-line-chart bg-red"></i>Profit & Loss
          </li>
        </Link>
        <Link to={"/results"} className="options">
          <li onClick={() => setOpenProfile(false)}>
            <i className="menu-icon fa fa-trophy bg-red"></i>Results
          </li>
        </Link>
        <Link to={"/chip-Setting"} className="options">
          <li onClick={() => setOpenProfile(false)}>
            <i className="menu-icon fa  fa-gears  bg-red"></i>Chips Settings
          </li>
        </Link>
        <Link to={"/change-password"} className="options">
          <li onClick={() => setOpenProfile(false)}>
            <i className="menu-icon fa fa-key bg-red"></i>Change Password
          </li>
        </Link>
        <li
          onClick={() => {
            setOpenProfile(false);
            setIsModeldisplay("Terms and Condition");
          }}
        >
          <i className="menu-icon fa fa-info bg-red"></i>Terms and Condition
        </li>
        <li
          onClick={() => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userDetails");
            setToken(null); // Notify React of the change
            navigate("/login");
            handellogOut();
          }}
        >
          <i className="menu-icon fa fa-power-off bg-red"></i>Logout
        </li>
      </ul>
    </div>
  );
};

export default UserProfile;
