import React, { useEffect, useState } from 'react'
import BoxCard from '../rightdivisoncomponents/BoxCard'
import { json } from 'react-router-dom'

const ChipSettingPage = () => {
    const [chipsValue, setChipsValue] = useState([]);

    const getLocalData = () => {
        let chipsvalue = JSON.parse(localStorage.getItem("AllchipsValue"));
        setChipsValue(chipsvalue || [10, 20, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120])
    }

    useEffect(() => {
        getLocalData()
    }, [])




    const handelChange = (index, event) => {
        // console.log(index);
        const newArry = [...chipsValue]
        newArry[index] = event.target.value;
        setChipsValue(newArry)
    }

    const handelSubmit = () => {

        localStorage.setItem("AllchipsValue", JSON.stringify(chipsValue))

    }

    const handelReset = () => {
        localStorage.removeItem("AllchipsValue")
        getLocalData()
    }


    return (
        <div className='chip-setting'>
            <BoxCard tittle={'CUSTOM CHIPS SETTING'}>
                <div className="container d-flex">
                    <div className='w-50 border1 '>
                        <div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Chips Name</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[0]} onChange={(e) => handelChange(0, e)} />
                            </div>

                        </div>
                        <div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Chips Name</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[1]} onChange={(e) => handelChange(1, e)} />
                            </div>

                        </div><div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Chips Name</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[2]} onChange={(e) => handelChange(2, e)} />
                            </div>

                        </div>
                        <div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Chips Name</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[3]} onChange={(e) => handelChange(3, e)} />
                            </div>

                        </div><div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Chips Name</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[4]} onChange={(e) => handelChange(4, e)} />
                            </div>

                        </div><div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Chips Name</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[5]} onChange={(e) => handelChange(5, e)} />
                            </div>

                        </div>



                    </div>
                    <div className='w-50 border1 '>
                        <div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Your Chips</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[6]} onChange={(e) => handelChange(6, e)} />
                            </div>

                        </div>
                        <div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Your Chips</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[7]} onChange={(e) => handelChange(7, e)} />
                            </div>

                        </div><div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Your Chips</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[8]} onChange={(e) => handelChange(8, e)} />
                            </div>

                        </div>
                        <div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Your Chips</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[9]} onChange={(e) => handelChange(9, e)} />
                            </div>

                        </div><div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Your Chips </p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[10]} onChange={(e) => handelChange(10, e)} />
                            </div>

                        </div><div className="row my-4 m-0">
                            <div className="col-md-3 px-2  px-lg-2 border1 title ">
                                <p className='mb-1'>Your Chips</p>
                            </div>
                            <div className="col-md-9 px-2  px-lg-4 border1">
                                <input className='w-100' type="text" value={chipsValue[11]} onChange={(e) => handelChange(11, e)} />
                            </div>

                        </div>


                    </div>

                </div>
                <div className="footer d-flex align-items-center justify-content-end  border-top pt-2">
                    <button onClick={handelReset}>Reset</button>
                    <button onClick={handelSubmit}>Submit</button>
                </div>
            </BoxCard>
        </div>
    )
}

export default ChipSettingPage
