import React, { useContext, useEffect, useState } from "react";
import context from "../context/createContext";
import { useDispatch, useSelector } from "react-redux";
import { placeBet } from "../dataBaseService/service";
import { Slide, toast } from "react-toastify";
import Loader from "../pages/Loader";
import { validateToken } from "../authservice/tokenalidation";
import { clearBetSlip } from "../../redux/betSlipSlice";

const BetSlip = ({ getMarketData }) => {
  const [stakeValue, setStakeValue] = useState("");
  const [rateValue, setRateValue] = useState("");
  const [final, setFinal] = useState(null);
  const { betSlipOpen, SetBetSlipOpen } = useContext(context);
  const betSlipData = useSelector((state) => state.betSlipData);
  const token = sessionStorage.getItem("token");
  const { setBetslipCalulation } = useContext(context);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setRateValue(betSlipData.price);
  }, [betSlipData]);

  useEffect(() => {
    console.log(rateValue, stakeValue);
    if (betSlipData.trade == "fancy") {
      let num = (rateValue / 100) * stakeValue;
      let TwodegetNum = num.toFixed(2);
      setFinal(TwodegetNum);
    } else if (betSlipData.trade == "bookmaker") {
      let num = (rateValue * stakeValue) / 100;
      let TwodegetNum = num.toFixed(2);
      setFinal(TwodegetNum);
    } else {
      let num = (rateValue - 1) * stakeValue;
      let TwodegetNum = num.toFixed(2);
      setFinal(TwodegetNum);
    }

    setBetslipCalulation({
      game: betSlipData.sportsName,
      eventId: betSlipData.eventId,
      marketId: betSlipData.marketId,
      trade: betSlipData.trade,
      selectionId: betSlipData.selectionId,
      type: betSlipData.tradeOn,
      rate: rateValue,
      stake: stakeValue || 0,
      final: final,
    });
  }, [rateValue, final, stakeValue]);



  const handelChange = (e) => {
    if (e.target.value == "") {
      setStakeValue("");
    } else {
      setStakeValue(Number(e.target.value));
    }
  };



  const handelNumclick = (value) => {
    let num = stakeValue.toString();
    let nvalue = value.toString();
    let totalstring = num + nvalue;
    console.log(typeof totalstring);
    setStakeValue(Number(parseFloat(totalstring)));
  };



  const handelMinus = () => {
    let value = parseFloat(stakeValue || 0) - 1;
    setStakeValue(value);
  };



  const handelPlus = () => {
    let value = parseFloat(stakeValue || 0) + 1;
    setStakeValue(value);
  };

  const handelRateMinus = () => {
    const value = parseFloat(rateValue) - 0.01;
    setRateValue(Number(value.toFixed(2)));
  };



  const handelRatePlus = () => {
    // let value = parseFloat(rateValue + 0.01)
    // setRateValue(Number(value).toFixed(2))
    // setRateValue(Number(rateValue + 0.01).toFixed(2))
    const value = parseFloat(rateValue) + 0.01;
    setRateValue(Number(value.toFixed(2)));
  };



  const cutNum = () => {
    let num = Number(stakeValue.toString().slice(0, -1));
    setStakeValue(num);
  };



  const handelCancel = () => {
    setBetslipCalulation("");

    setStakeValue("");

    dispatch(clearBetSlip());

    SetBetSlipOpen(false);
  };



  const handelPlaceBet = async () => {
    let betData = {
      game: betSlipData.sportsName,
      eventId: betSlipData.eventId,
      marketId: betSlipData.marketId,
      trade: betSlipData.trade,
      selectionId: betSlipData.selectionId,
      type: betSlipData.tradeOn,
      rate: rateValue,
      stake: stakeValue,
      runs: betSlipData.Runs,
    };
    // console.log(betSlipData);
    // console.log(betData);

    try {
      setLoading(true);
      let res = await placeBet(token, betData);
      console.log(res);
      setLoading(false);

      if (res && res.status == 200) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Slide,
        });
        await validateToken(token);
        SetBetSlipOpen(false);
        setBetslipCalulation("");
        setStakeValue("");
        dispatch(clearBetSlip());
        getMarketData();
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Slide,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };



  // console.log(userInput);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className={`${betSlipOpen ? "d-block" : "d-none"
            }  py-4 bet-slip   bg-white`}
        >
          <div className="row  m-0   ">
            <div
              className={`col-8  slipheader  ${betSlipData.tradeOn == "back" || betSlipData.tradeOn == "yes"
                ? "headerBack"
                : "headerLay"
                }`}
            >
              {betSlipData.tradeOn.charAt(0).toUpperCase() +
                betSlipData.tradeOn.slice(1)}
              (Bet For)
            </div>
            <div
              className={`col-4  slipheader ${betSlipData.tradeOn == "back" || betSlipData.tradeOn == "yes"
                ? "headerBack"
                : "headerLay"
                }`}
            >
              Return
            </div>
          </div>
          <div className="row  p-2">
            <div className="col-8  text-start">
              <h3>{betSlipData.team} </h3>
            </div>
            <div
              className={`col-4  text-start d-flex align-items-center total ${betSlipData.tradeOn == "back" ? "totalback" : "totallay"
                }`}
            >
              {final}
            </div>
          </div>
          <div className="price-section d-flex  m-2 my-3 ">
            {betSlipData.trade == "fancy" ? (
              <div className="price w-50 me-4  ">{betSlipData.Runs} </div>
            ) : betSlipData.trade == "bookmaker" ? (
              <div className="price w-50 me-4  ">{rateValue} </div>
            ) : (
              <div className="input-price w-50 d-flex   me-4">
                <button onClick={handelRateMinus}>
                  <i className="fa fa-minus"></i>
                </button>
                <input
                  type="number"
                  placeholder="rate"
                  value={rateValue}
                  readOnly
                />
                <button onClick={handelRatePlus}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            )}

            <div className="input-price w-50 d-flex   ">
              <button onClick={handelMinus}>
                <i className="fa fa-minus"></i>
              </button>
              <input
                onChange={handelChange}
                value={stakeValue}
                type="number"
                placeholder="Stake"
              />
              <button onClick={handelPlus}>
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <div className="numbersection d-flex m-2  my-3">
            <div className="numbers ">
              <button onClick={() => handelNumclick("1")}>1</button>
              <button onClick={() => handelNumclick("2")}>2</button>
              <button onClick={() => handelNumclick("3")}>3</button>
              <button onClick={() => handelNumclick("4")}>4</button>
              <button onClick={() => handelNumclick("5")}>5</button>
              <button onClick={() => handelNumclick("6")}>6</button>
              <button onClick={() => handelNumclick("7")}>7</button>
              <button onClick={() => handelNumclick("8")}>8</button>
              <button onClick={() => handelNumclick("9")}>9</button>
              <button onClick={() => handelNumclick("0")}>0</button>
              <button onClick={() => handelNumclick("00")}>00</button>
              <button onClick={() => handelNumclick("000")}>000</button>
            </div>
            <button className="back-btn" onClick={cutNum}>
              <i className="fa fa-caret-left"></i>
            </button>
          </div>
          <div
            className={`money-section m-2 ${betSlipData.tradeOn == "back" ? "backbutton" : "laybutton"
              }`}
          >
            <button
              onClick={() => setStakeValue(Number(stakeValue + 1000))}
              className="my-1"
            >
              1000
            </button>
            <button
              onClick={() => setStakeValue(Number(stakeValue + 2000))}
              className="my-1"
            >
              2000
            </button>
            <button
              onClick={() => setStakeValue(Number(stakeValue + 5000))}
              className="my-1"
            >
              5000
            </button>
            <button
              onClick={() => setStakeValue(Number(stakeValue + 10000))}
              className="my-1"
            >
              10000
            </button>
            <button
              onClick={() => setStakeValue(Number(stakeValue + 50000))}
              className="my-1"
            >
              50000
            </button>
            <button
              onClick={() => setStakeValue(Number(stakeValue + 100000))}
              className="my-1"
            >
              100000
            </button>
            <button onClick={() => setStakeValue("")}>clear</button>
          </div>
          <div className="footer p-2">
            <button onClick={handelCancel}>Cancel</button>
            <button onClick={handelPlaceBet}>Place Bet</button>
          </div>
        </div>
      )}
    </>
  );
};

export default BetSlip;
