import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { validateToken } from '../authservice/tokenalidation';
import Loader from '../pages/Loader';

function ProtectedRoute({ element }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initial loading state
  const token = sessionStorage.getItem('token');


  const valiRes = async () => {
    try {
      const res = await validateToken(token);
      console.log(res);

      setIsAuthenticated(res)

    } catch (error) {
      console.log(error);

    }
  }
  useEffect(() => {
    valiRes()
    console.log("valiRes");

  })



  // Check if token exists

  console.log(isAuthenticated);
  if (isAuthenticated == null) {
    return (<Loader />)
  }
  else {
    if (token && isAuthenticated) {
      return element; // If the token exists, render the element
    } else {
      return <Navigate to="/login" replace />; // Redirect to login if no token
    }
  }


}

export default ProtectedRoute;
