import React, { useContext } from "react";
import { SlScreenDesktop } from "react-icons/sl";
import { FaStarOfLife } from "react-icons/fa6";
import { Link } from "react-router-dom";
import context from "../context/createContext";
import { convertISOToCustomDate } from "../toolFunction";

const MatchRow = ({ marketdeatils }) => {
  const { setMarketToLocal } = useContext(context);

  const startdate = convertISOToCustomDate(marketdeatils.marketStartTime);

  return (
    <Link
      className="link"
      to={`/market/${marketdeatils.marketId}`}
      onClick={() => setMarketToLocal(marketdeatils.marketId)}
    >
      <div className="match-row border1 d-flex py-3 mb-2">
        {/* Match Name Section */}
        <div className="matches-name border1 d-flex px-2">
          <i className="fa fa-asterisk me-2"></i>
          <section>
            <div className="d-flex align-items-center">
              <p className="text-start m-0">
                {marketdeatils.marketName} -
                <span className="mx-2">{marketdeatils.series_name}-</span>
                <span className="text-nowrap date">{startdate}</span>
                {marketdeatils.inplay && <button>in-play</button>}
                <span className="mx-2">
                  {marketdeatils.liveTv && (
                    <i className="fa fa-television fs-6" />
                  )}
                </span>
              </p>
            </div>
          </section>
        </div>

        {/* Match Score Section */}
        <div className="matches-score border1">
          {marketdeatils.runners.length === 3 ? (
            <>
              {marketdeatils.runners.map((runner, index) => (
                <div key={index} className="box border1">
                  <section>{runner.back[0]?.price || "-"}</section>
                  <section>{runner.lay[0]?.price || "-"}</section>
                </div>
              ))}
            </>
          ) : marketdeatils.runners.length === 2 ? (
            <>
              <div className="box border1">
                <section>
                  {marketdeatils.runners[0]?.back[0]?.price || "-"}
                </section>
                <section>
                  {marketdeatils.runners[0]?.lay[0]?.price || "-"}
                </section>
              </div>
              <div className="box border1">
                <section>-</section>
                <section>-</section>
              </div>
              <div className="box border1">
                <section>
                  {marketdeatils.runners[1]?.back[0]?.price || "-"}
                </section>
                <section>
                  {marketdeatils.runners[1]?.lay[0]?.price || "-"}
                </section>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </Link>
  );
};

export default MatchRow;
