import React, { useContext, useRef, useState } from 'react'
import context from '../context/createContext';

import { useClickAway } from 'react-use';

const Model = ({ title, children }) => {
  const { isModelDisplay, setIsModeldisplay } = useContext(context)
  const modelRef = useRef(null);
  useClickAway(modelRef, (event) => {
    // Only close if the popup is shown
    if (isModelDisplay == title) {
      setIsModeldisplay(false);
    }
  })




  return (
    <div className='model' style={{ display: isModelDisplay == title ? "block" : "none" }}>
      <div className="row m-0">
        <div className="col-lg-9   mx-auto my-4">
          <div ref={modelRef} className="model-box">
            <div className="header d-flex align-items-center justify-content-between p-3">
              <h1 className='m-0'>{title}</h1>
              <button onClick={() => { setIsModeldisplay((pre) => !pre) }}>×</button>
            </div>
            <div className="content bg-white p-3 ">
              {children}
            </div>

          </div>
        </div>
      </div>


    </div>
  )
}

export default Model


