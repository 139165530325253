
import axios from 'axios'
export const baseUrl = process.env.REACT_APP_BASEURL;
const key = process.env.REACT_APP_ARCHER_KEY;
// export const baseUrl = 'http://192.168.1.15:3000/'

function apiAuth(endPoint) {

  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}${endPoint}`,
    headers: {
      'xArcherKey': key
    }
  };
}


export const getAllSports = async () => {
  try {
    const config = apiAuth('api/allevents');
    const response = await axios.request(config);
    return (response.data.sports)

  }
  catch (error) {
    console.log("getAllSports-Error" + error);
  }
}



export const getAllGames = async () => {
  try {
    const config = apiAuth('api/allsports');
    const response = await axios.request(config);
    return (response.data)
  }
  catch (error) {
    console.log("getAllGames-Error" + error);
  }
}


export const getAllInplayGames = async () => {
  try {
    const config = apiAuth('api/inplay');
    const response = await axios.request(config);

    return (response.data)
  }
  catch (error) {
    console.log("getAllInplayGames-Error" + error);
  }
}


export const getMarketInfo = async (marketId, token) => {
  console.log("marketInfo");

  try {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}user/marketByUser/${marketId}`,
      headers: {
        token: token
      }
    }
    const response = await axios.request(config);


    return (response.data)
  }
  catch (error) {
    console.log("getAllInplayGames-Error" + error);
  }
}

export const placeBet = async (token, betData) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/placeBet`,
    headers: {
      token: token
    },
    data: betData
  }


  try {

    return await axios.request(config)

  }
  catch (error) {
    console.log("error to place bet" + error);
    return error.response
  }
}


export const GetUserLiability = async (token) => {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/activeBets`,
    headers: {
      token: token
    }
  }

  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }

}

export const getUserStatment = async (token, pageNo, length) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/userStatement`,
    headers: {
      token: token
    },
    data: {
      page: parseInt(pageNo),
      length: length
    }
  }


  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }

}

export const getUserBetHistory = async (token, marketId) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/betHistory`,
    headers: {
      token: token
    },
    data: {
      marketId: marketId
    }
  }


  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }

}

export const getUserprofitLoss = async (token, length, fromDate, toDate, pageNo) => {
  console.log(length, fromDate, toDate, pageNo);


  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/matchResults`,
    headers: {
      token: token
    },
    data: {
      length,
      fromDate, toDate, pageNo
    }
  }


  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }

}


export const getLaderDetails = async (token, marketId) => {

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/fancylader`,
    headers: {
      token: token
    },
    data: { marketId }  // Pass the parameters here
  }


  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }
}

export const getMatchedBets = async (token, marketId) => {

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/betHistory`,
    headers: {
      token: token
    },
    data: { marketId }  // Pass the parameters here
  }


  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }
}

export const getUserResult = async (token, show, fromDate, toDate, pageNo) => {

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}user/matchResults`,
    headers: {
      token: token
    }
  }


  try {
    return await axios.request(config);
  }
  catch (error) {
    return error;
  }

}
