import React, { useContext } from 'react'
import context from '../context/createContext'
import BetSlip from './BetSlip'

const BetSlipBox = ({ getMarketData }) => {
  const { setIsModeldisplay } = useContext(context)
  const { betSlipOpen, isMobile, rightBoxRef, handleScrollToBox } = useContext(context)

  return (
    <div ref={rightBoxRef} className={`${isMobile ? betSlipOpen ? "d-flex d-lg-block " : "d-none" : ''} betslip-box  mb-3`}>
      <div className="header">
        <h1>betSlip</h1>
        <button onClick={() => { setIsModeldisplay("Matches") }}><i className="fa-solid fa-plus"></i></button>
      </div>
      <div className="content  w-100">
        <BetSlip getMarketData={getMarketData} />
      </div>
    </div>
  )
}

export default BetSlipBox;