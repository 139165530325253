import React, { useContext, useState } from "react";
import miniPlayer from "../../assest/miniplayer.svg";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import VideoPlayer from "./VideoPlayer";
import context from "../context/createContext";
const LiveBoardcast = ({ liveTv }) => {
  const { isMobile, isShowTv, setIsShowTv } = useContext(context);

  return (
    <div className="live-broadcast mb-3">
      {isMobile ? (
        ""
      ) : (
        <div className="header">
          <h1>LIVE BROADCASt</h1>
          <img
            onClick={() => setIsShowTv(!isShowTv)}
            src={miniPlayer}
            alt="miniplayer"
          />
        </div>
      )}
      {isShowTv ? (
        liveTv ? (
          <div className="videobox" style={{ width: "100%", height: "300px" }}>
            <VideoPlayer url={`${liveTv}+&autoplay=1&mute=0&key=helloworld`} />
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default LiveBoardcast;
