import { useContext, useEffect, useState } from 'react';
import LOGO from '../../assest/powerbets-logo.png'
import { RiFootballLine } from "react-icons/ri";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import menuitem from '../../assest/menu.json'
import { Link } from 'react-router-dom';
import context from '../context/createContext';
import { motion, AnimatePresence } from 'framer-motion';


const SlideMenu = () => {
    const { setOpenMenu, allsports, setInplayMode, setMarketToLocal } = useContext(context)
    const [clickedbutton, setclickedbutton] = useState({ submenu: "", childmenu: "" })

    const handelMenuClick = (clickedDataId) => {
        if (clickedDataId == clickedbutton.submenu) {
            setclickedbutton((prevState) => ({
                ...prevState,
                submenu: "",
            }));
        }
        else {
            setclickedbutton((prevState) => ({
                ...prevState,
                submenu: clickedDataId,
            }));
        }

    };

    const handelSubClick = (dataid) => {

        if (dataid == clickedbutton.childmenu) {
            setclickedbutton((prevState) => ({
                ...prevState,
                childmenu: "",
            }));
        }
        else {
            setclickedbutton((prevState) => ({
                ...prevState,
                childmenu: dataid,
            }));
        };

    }
    // console.log(allsports);

    return (
        <div className="side-menu">
            <ul className="option-list list-unstyled py-0 py-md-2">
                <Link to={'/home'} className='text-decoration-none' > <li className='  d-block  ' onClick={() => {
                    setOpenMenu(false)
                    setclickedbutton({ submenu: "allgames", childmenu: "" })
                    setInplayMode(false)
                }}>
                    <section className={`${clickedbutton.submenu == "allgames" ? "active" : ""} menu-item d-flex align-items-center  justify-content-between `} >
                        <span className='d-flex align-items-center'><i className="fa fa-soccer-ball-o" />
                            <p className='m-0 ms-1'>All Games</p></span>
                    </section></li></Link>
                <Link to={'/home'} className='text-decoration-none' >  <li className='  d-block  ' onClick={() => {
                    setOpenMenu(false)
                    setclickedbutton({ submenu: "inplay", childmenu: "" })
                    setInplayMode(true)
                }}>
                    <section className={`${clickedbutton.submenu == "inplay" ? "active" : ""} menu-item d-flex align-items-center  justify-content-between `}>
                        <span className='d-flex align-items-center'><i className="fa fa-soccer-ball-o" />
                            <p className='m-0 ms-1'>In play</p></span>
                    </section></li></Link>
                {allsports ?
                    allsports.map((item, index) => {
                        return (
                            <li key={index} className='  d-block  '>
                                <section onClick={() => handelMenuClick(item.name)} className={`${clickedbutton.submenu == item.name ? "active" : ""} menu-item d-flex align-items-center  justify-content-between  `}>
                                    <span className='d-flex align-items-center'><i className="fa fa-soccer-ball-o" />
                                        <p className='m-0 ms-1'>{item.name}</p></span>
                                    {clickedbutton.submenu == item.name ? <FaAngleDown /> : <FaAngleLeft />}
                                </section>
                                <AnimatePresence>

                                    {
                                        clickedbutton.submenu == item.name ? <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                            className="submenu"
                                        >
                                            <ul className='option-list-submenu list-unstyled'>
                                                {item.categories.map((match, index) => {

                                                    return (<li key={index} className=' text-start'>
                                                        <section onClick={() => handelSubClick(match.name)} className='d-flex sub-option  align-items-center justify-content-between' >
                                                            <span><i className="fa fa-chain me-2"></i>
                                                                {match.name}</span>
                                                            {match.name == clickedbutton.childmenu ? <FaAngleDown /> : <FaAngleLeft />}
                                                        </section>
                                                        <AnimatePresence>
                                                            {
                                                                match.name == clickedbutton.childmenu ? <motion.div
                                                                    initial={{ opacity: 0, height: 0 }}
                                                                    animate={{ opacity: 1, height: 'auto' }}
                                                                    exit={{ opacity: 0, height: 0 }}
                                                                    transition={{ duration: 0.3 }}
                                                                    className="submenu"
                                                                > <ul className='childmenu-list p-0'>
                                                                        {
                                                                            match.matches.map((matchdet, index) => {

                                                                                return (
                                                                                    <Link to={`/market/${matchdet.marketId}`} key={index} onClick={() => setMarketToLocal(matchdet.marketId)} style={{ textDecoration: 'none', color: 'inherit' }} className='link'><li className='list-unstyled text-start'>
                                                                                        <section>
                                                                                            {matchdet.name}
                                                                                        </section>

                                                                                    </li>
                                                                                    </Link>
                                                                                )
                                                                            })

                                                                        }
                                                                    </ul></motion.div> : ""

                                                            }
                                                        </AnimatePresence>
                                                    </li>)

                                                })}

                                            </ul>  </motion.div> : ""

                                    }
                                </AnimatePresence>


                            </li>
                        )
                    })
                    : ""}

            </ul>


        </div>
    )
}
export default SlideMenu    