import React, { useEffect } from 'react'
import arrow from '../../assest/arrow.svg'
import menu from '../../assest/image1.svg'
import left from '../../assest/image (1).svg'
import right from '../../assest/image (2).svg'
import rightStop from '../../assest/image (3).svg'
import leftStop from '../../assest/image.svg'
import FilterationComponents from '../rightdivisoncomponents/FilterationComponents'
import { useState } from 'react'
import { getAllSports, getUserResult } from '../dataBaseService/service'
import { convertISOToCustomDate } from '../toolFunction'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns'



const currentdate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format YYYY-MM-DD}
}

const ResultPage = () => {
    const [showFilterOf, setShowFilterOf] = useState('')
    const [pageNo, setpageNo] = useState(1);
    const [show, setShow] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [allResults, setAllresults] = useState([])
    const token = sessionStorage.getItem('token')
    const [filterationData, setFilterationData] = useState([])
    const [selectedSports, setSelectedSports] = useState("")
    const [selectedTurnament, setSelectedTurnament] = useState("")
    const [fromDate, setFromDate] = useState(currentdate);
    const [toDate, setToDate] = useState(currentdate);


    const handelDateChange = (date, type) => {
        console.log(format(date, 'yyyy-MM-dd'), type);
        const formattedDate = format(date, 'yyyy-MM-dd');
        if (type == "fromdate") {
            setFromDate(formattedDate);
        } else if (type == "todate") {
            setToDate(formattedDate);
        }
    };

    const goToLastPage = () => {
        setpageNo(totalPage);
    };

    const goToFirstPage = () => {
        setpageNo(1);
    };

    const pageDown = () => {
        if (pageNo > 1) {
            setpageNo(pageNo - 1);
        }
    };

    const pageUp = () => {
        if (pageNo < totalPage) {
            setpageNo(pageNo + 1);
        }
    };

    const getFilterationData = async () => {
        try {
            let res = await getAllSports();
            if (res) {
                setFilterationData(res)
            }
        } catch (error) {

        }
    }

    const getData = async () => {
        try {
            let res = await getUserResult(token);
            // console.log(res)

            if (res && res.status == 200) {
                setAllresults(res.data.results)
            }

        } catch (error) {

        }
    }
    useEffect(() => {
        getFilterationData()
        getData()
    }, [])

    const handelSportsChange = (e) => {
        console.log(e.target.value);

        setSelectedSports(e.target.value)
    }
    const handelTurnamentChange = (e) => {
        console.log(e.target.value);

        setSelectedSports(e.target.value)
    }

    console.log(filterationData);


    return (
        <div className='result-page  p-2'>
            <div className="container text-start ">
                <div className="row ">
                    <div className=" col-6 col-md-2  border1 my-2">
                        <h5>Sports:</h5>
                        <select onChange={handelSportsChange} className="w-100 p-2" name="sports" id="">
                            <option value="">select sports</option>
                            {filterationData ? filterationData.map((ele, index) => {
                                return (
                                    <option value={index}>{ele.name}</option>
                                )

                            }) : ""}
                        </select>
                    </div>
                    <div className=" col-6 col-md-2  border1 my-2">
                        <h5>Tournaments:</h5>
                        <select className="w-100 p-2" name="sports" id="">
                            <option value="">select sports</option>
                            {selectedSports ? filterationData[selectedSports].categories.map((ele, index) => {
                                console.log(ele);

                                return (
                                    <option value={index}>{ele.name}</option>
                                )

                            }) : ""}
                        </select>
                    </div>
                    <div className=" col-6 col-md-4  border1 my-2">
                        <h5>Matches:</h5>
                        <select className="w-100 p-2" name="sports" id="">
                            <option value="cricket">cricket</option>
                            <option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option>
                        </select>
                    </div>
                    <div className="col-6 col-md-4   my-2 border1">
                        <h5>Markets:</h5>
                        <select className="w-100 p-2" name="sports" id="">
                            <option value="cricket">cricket</option>
                            <option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option><option value="cricket">cricket</option>
                        </select>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col-12  col-md-2  border1 my-2">
                        <DatePicker calendarClassName="custom-calendar" wrapperClassName="w-100 cursor-pointer" className="w-100" placeholderText="Select a date" dateFormat="dd/MM/yyyy" selected={fromDate} onChange={(date) => handelDateChange(date, "fromdate")} />
                    </div>
                    <div className="col-12 col-md-2 my-2   border1">
                        <DatePicker calendarClassName="custom-calendar" wrapperClassName="w-100" className="w-100" placeholderText="Select a date" dateFormat="dd/MM/yyyy" selected={toDate} onChange={(date) => handelDateChange(date, "todate")} />
                    </div>
                    <div className=" col-12 col-md-8 d-flex  border1 checker-box my-2">
                        <section className='d-flex align-items-center'><input className="m-0" type="checkbox" id='market' /><label htmlFor="market" className='mx-2 my-0'>Market</label ></section>
                        <section className='d-flex align-items-center'><input className="m-0" type="checkbox" id='book-marker' /><label htmlFor="book-marker" className='mx-2 my-0'>Book Maker</label></section>
                        <section className='d-flex align-items-center'><input className="m-0" type="checkbox" id='manual-odds' /><label htmlFor="manual-odds" className='mx-2 my-0'>Manual Odds</label></section>
                        <section className='d-flex align-items-center'><input className="m-0" type="checkbox" id='line-market' /><label htmlFor="line-market" className='mx-2 my-0'>Line Market</label></section>
                        <section className='d-flex align-items-center'><input className="m-0" type="checkbox" id='adv-session' /><label htmlFor="adv-session" className='mx-2 my-0'>Adv Session</label></section>

                    </div>
                </div>
                <div className="row ">
                    <div className="col-6  col-lg-2 ">
                        <div className='bg-white show-div   d-flex align-items-center'> <section className='p-2'>Show: </section>
                            <select defaultValue={10} className=" p-2 flex-grow-1" name="show" id="show">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content mt-3">
                <table className='text-start '>
                    <thead>
                        <tr className=''>
                            <th className=''>
                                <span className=' w-100 d-flex align-items justify-content-between'>
                                    <span>Date <img src={arrow} alt="" />
                                    </span> <img onClick={() => setShowFilterOf(1)} src={menu} alt="" className='menu-icon' />
                                    <FilterationComponents isShow={showFilterOf == 1 ? true : false} setShowFilterOf={setShowFilterOf} />
                                </span>
                            </th>
                            <th className=''><span className=' w-100 d-flex align-items justify-content-between'><span>Market Type <img src={arrow} alt="" /></span> <img onClick={() => setShowFilterOf(2)} src={menu} alt="" className='menu-icon' /><FilterationComponents isShow={showFilterOf == 2 ? true : false} setShowFilterOf={setShowFilterOf} /></span></th>
                            <th className=''><span className=' w-100 d-flex align-items justify-content-between'><span>Market Name <img src={arrow} alt="" /></span> <img onClick={() => setShowFilterOf(3)} src={menu} alt="" className='menu-icon' /></span><FilterationComponents isShow={showFilterOf == 3 ? true : false} setShowFilterOf={setShowFilterOf} /></th>
                            <th className=''><span className=' w-100 d-flex align-items justify-content-between'><span>Result<img src={arrow} alt="" /></span> <img onClick={() => setShowFilterOf(4)} src={menu} alt="" className='menu-icon' /></span><FilterationComponents isShow={showFilterOf == 4 ? true : false} setShowFilterOf={setShowFilterOf} /></th>

                        </tr>
                    </thead>
                    <tbody className='position-relative'>
                        {allResults ? allResults.map((ele, index) => {

                            return (<tr key={index} className='position-relative'>
                                <td>{convertISOToCustomDate(ele.date)}</td>
                                <td>{ele.type}</td>
                                <td> {ele.description}</td>
                                <td>{ele.resultVeiw}</td>
                            </tr>)
                        }) : ""}

                    </tbody>

                </table>

            </div>
            <div className="footer  d-flex align-items-center justify-content-end ">


                <section className="me-2">
                    {(pageNo - 1) * show + 1} to {Math.min(pageNo * show, totalData)} of{" "}
                    {totalData}{" "}
                </section>
                <section>
                    <button onClick={goToFirstPage}>
                        <img src={leftStop} alt="" />
                    </button>
                    <button onClick={pageDown}>
                        <img src={left} alt="" />
                    </button>
                    <span>
                        page {pageNo} of {totalPage}
                    </span>
                    <button onClick={pageUp}>
                        <img src={right} alt="" />
                    </button>
                    <button onClick={goToLastPage}>
                        <img src={rightStop} alt="" />
                    </button>
                </section>

                {/* <section className='me-2'>1 to 5 of 5 </section>
                <section>
                    <button><img src={leftStop} alt="" /></button><button><img src={left} alt="" /></button>
                    <span>page 1 of 1</span>
                    <button><img src={right} alt="" /></button><button><img src={rightStop} alt="" /></button>
                </section> */}
            </div>

        </div>
    )
}

export default ResultPage
