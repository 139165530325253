import React, { useContext } from "react";
import Header from "./Header";
import LeftDivison from "./LeftDivison";
import RightDivison from "./RightDivison";
import { ToastContainer } from "react-toastify";
import context from "../context/createContext";
import OneClickBetEdite from "../rightdivisoncomponents/OneClickBetEdite";
import Model from "../pages/Model";
import TermAndConditionContent from "../rightdivisoncomponents/TermAndConditionContent";
import BetslipAdd from "../rightdivisoncomponents/BetslipAdd";
import Lability from "../rightdivisoncomponents/Lability";
import ClientBetHistory from "../rightdivisoncomponents/ClientBetHistory";
import ClientProfitAndLoss from "../rightdivisoncomponents/ClientProfitAndLoss";
import BookPosition from "../rightdivisoncomponents/BookPosition";

function ProtectedLayout({ setToken }) {
  const { loading, openMenu } = useContext(context);
  return (
    <>
      <Header />
      <div
        className={`content-box d-flex ${openMenu ? "active-menu" : ""
          } border1 border1-info border1-5`}
      >
        <LeftDivison />
        <RightDivison setToken={setToken} />
      </div>
      <Model title={"Matches"}>
        <BetslipAdd />
      </Model>
      <Model title={"One Click Bet Stake"}>
        <OneClickBetEdite />
      </Model>
      <Model title={"Terms and Condition"}>
        <TermAndConditionContent />
      </Model>
      <Model title={"Rules"}>
        <TermAndConditionContent />
      </Model>
      <Model title={"Liability"}>
        <Lability />
      </Model>
      <Model title={"Client Bet History"}>
        <ClientBetHistory />
      </Model>
      <Model title={"Client Profit And Loss"}>
        <ClientProfitAndLoss />
      </Model>
      <Model title={"Book Position"}>
        <BookPosition />
      </Model>
    </>
  );
}

export default ProtectedLayout;
