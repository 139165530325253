import { jwtDecode } from 'jwt-decode';
import { VeryfiUser } from './service';

export const validateToken = async (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const VeryfiRes = await VeryfiUser(token)
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

        if (VeryfiRes.data.status == "online" && decodedToken.exp > currentTime) {
            sessionStorage.setItem('userDetails', JSON.stringify(VeryfiRes.data.userDetails));

            return true
        }
        else {
            return false
        }

    } catch (error) {
        return false; // Token is invalid or decoding failed
    }
};

