import React from 'react'

function VideoPlayer({url}) {
    return (
        <div style={{ width: '100%', height: '100%' }}>
        <iframe
            width="100%"
            height="100%"
            src={url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Live Video Stream"
        />
    </div>
  
    );
  }
  
  export default VideoPlayer;