import React, { useState } from "react";
import BoxCard from "../rightdivisoncomponents/BoxCard";
import menuitem from "../../assest/menu.json";
import Plus from "../../assest/Mplus.svg";
import { motion, AnimatePresence } from "framer-motion";

const BetslipAdd = () => {
  const [menuItem, setMenuItem] = useState(menuitem.sports);
  const [clickedbutton, setclickedbutton] = useState("");

  const handelSubClick = (dataid) => {
    if (dataid == clickedbutton) {
      setclickedbutton("");
    } else {
      setclickedbutton(dataid);
    }
  };

  return (
    <div className="matches text-start">
      {menuItem.map((item, index) => {
        return (
          <li key={index} className="  d-block   border1  border1-warning  ">
            <section className=" model-item d-flex ">
              <span className="d-flex align-items-center p-2">
                <span className="glyphicon glyphicon-asterisk"></span>
                <p className="m-0 ms-1">{item.name}</p>
              </span>
            </section>

            <ul className="model-submenu list-unstyled px-3  ">
              {item.categories.map((match, index) => {
                return (
                  <li
                    key={index}
                    className="model-submenu-item text-start  px-2 py-1 my-3"
                  >
                    <section
                      onClick={() => handelSubClick(match.name)}
                      className={`${
                        match.name == clickedbutton ? "text-style" : ""
                      } d-flex model-sub-option  align-items-center justify-content-between `}
                    >
                      <span>
                        {match.name == clickedbutton ? (
                          <span className="glyphicon glyphicon-menu-down me-2"></span>
                        ) : (
                          <span className="glyphicon glyphicon-menu-right me-2"></span>
                        )}
                        {match.name}
                      </span>
                    </section>
                    <AnimatePresence>
                      {match.name == clickedbutton ? (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="submenu"
                        >
                          {" "}
                          <ul className="model-sub-child p-0 ">
                            {match.matches.map((matchName, index) => {
                              return (
                                <li
                                  key={index}
                                  className="list-unstyled text-start p-2"
                                >
                                  <section className="d-flex align-items-center justify-content-between">
                                    {matchName}
                                    <img
                                      className="add-btn"
                                      src={Plus}
                                      alt=""
                                    />
                                  </section>
                                </li>
                              );
                            })}
                          </ul>{" "}
                        </motion.div>
                      ) : (
                        ""
                      )}
                    </AnimatePresence>
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </div>
  );
};

export default BetslipAdd;
