import React, { useContext, useEffect, useState } from "react";
import arrow from "../../assest/arrow.svg";
import menu from "../../assest/image1.svg";
import left from "../../assest/image (1).svg";
import right from "../../assest/image (2).svg";
import rightStop from "../../assest/image (3).svg";
import leftStop from "../../assest/image.svg";
import FilterationComponents from "../rightdivisoncomponents/FilterationComponents";
import { getUserStatment } from "../dataBaseService/service";
import { Slide, toast } from "react-toastify";
import context from "../context/createContext";
import { convertISOToCustomDate } from "../toolFunction";

const AccountStatemantPage = () => {
  const { setIsModeldisplay, setMarketIdForHistory } = useContext(context);
  const [showFilterOf, setShowFilterOf] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [up, setUp] = useState(true);
  const [show, setShow] = useState(10);
  const [pageNo, setpageNo] = useState(1);
  const token = sessionStorage.getItem("token");
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [statementData, setStatementData] = useState([]);

  const handelSortBy = (thed) => {
    if (sortBy == thed) {
      setUp(!up);
    } else {
      setSortBy(thed);
    }
  };


  const handelMenuClick = (event, clickIndex) => {
    event.stopPropagation(); // Prevents the event from bubbling to the <th> element
    setShowFilterOf(clickIndex);
  };



  const handelChange = (e) => {
    setpageNo(1);
    setShow(parseInt(e.target.value));
  };


  const pageDown = () => {
    if (pageNo > 1) {
      setpageNo(pageNo - 1);
    }
  };


  const pageUp = () => {
    if (pageNo < totalPage) {
      setpageNo(pageNo + 1);
    }
  };



  const getData = async () => {
    try {
      const res = await getUserStatment(token, pageNo, show);
      console.log(res);
      if (res && res.data.userStatement) {
        setStatementData(res.data.userStatement);
        setTotalData(res.data.total);
      } else {
        toast.error(res.data.userStatement, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Slide,
        });
      }
    } catch (error) { }
  };



  useEffect(() => {

    getData();
  }, [pageNo, show]);



  useEffect(() => {
    setTotalPage(Math.trunc(totalData / show + 1));
  }, [totalData, show]);



  const handelHistory = (marketId) => {
    setMarketIdForHistory(marketId);
    setIsModeldisplay("Client Bet History");
  };



  const goToLastPage = () => {
    setpageNo(totalPage);
  };



  const goToFirstPage = () => {
    setpageNo(1);
  };





  const condinationRender = (id, string) => {
    let lossRegers = /\b(Loss)\b/
    let profitregex = /\b(Profit)\b/

    if (lossRegers.test(string)) {
      return <span onClick={() => handelHistory(id)} className="text-danger">{string}</span>
    }
    else if (profitregex.test(string)) {
      return <span onClick={() => handelHistory(id)} className="text-success">{string}</span>
    }
    else {
      return <span className="text-dark">{string}</span>
    }
  }


  return (
    <div className="account-statement  p-2">
      <div className="container text-start">
        <div className="row ">
          <div className="col-lg-6 ">
            <h5>Search:</h5>
            <input className="w-50 h-auto bg-white py-1 " type="text" />
            <div className="w-50"></div>
          </div>
          <div className="col-lg-6 1 py-0  ">
            <div className="row 1 h-100 ">
              <section className="col-8 1  d-flex align-items-end m-0">
                <button className="">PDF</button>
                <button className="">CSV</button>{" "}
                <button className="">Excel</button>
              </section>
              <section className="col-4 mt-4 mt-md-0">
                <h5 className="d-none d-md-block">Show:</h5>
                <select
                  onChange={handelChange}
                  defaultValue={"10"}
                  name="show"
                  id="show"
                  className="w-100 m-0 "
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="content  mt-3  ">
        {
          (statementData.length == 0 ? (
            <p className="text-center w-100 py-4 fs-5 ">No Row To Show</p>
          ) : (
            <table className="  text-start ">
              <thead className=" ">
                <tr>
                  <th onClick={() => handelSortBy("Date")} className="">
                    {" "}
                    { }
                    <span className=" w-100 d-flex align-items justify-content-between">
                      <span>
                        Date{" "}
                        <img
                          src={arrow}
                          alt=""
                          class={sortBy == "Date" ? (up ? "updown" : "") : ""}
                        />
                      </span>
                      <img
                        src={menu}
                        onClick={(e) => handelMenuClick(e, 1)}
                        alt=""
                        className="menu-icon"
                      />
                      <FilterationComponents
                        isShow={showFilterOf == 1 ? true : false}
                        setShowFilterOf={setShowFilterOf}
                      />
                    </span>
                  </th>

                  <th onClick={() => handelSortBy("Type")} className="">
                    <span className=" w-100 d-flex align-items justify-content-between">
                      <span>
                        Type{" "}
                        <img
                          class={sortBy == "Type" ? (up ? "updown" : "") : ""}
                          src={arrow}
                          alt=""
                        />
                      </span>{" "}
                      <img
                        src={menu}
                        onClick={(e) => handelMenuClick(e, 2)}
                        alt=""
                        className="menu-icon"
                      />
                      <FilterationComponents
                        isShow={showFilterOf == 2 ? true : false}
                        setShowFilterOf={setShowFilterOf}
                      />
                    </span>
                  </th>

                  <th onClick={() => handelSortBy("Description")} className="">
                    <span className=" w-100 d-flex align-items justify-content-between">
                      <span>
                        Description {" "}
                        <img
                          class={
                            sortBy == "Description" ? (up ? "updown" : "") : ""
                          }
                          src={arrow}
                          alt=""
                        />
                      </span>{" "}
                      <img
                        src={menu}
                        onClick={(e) => handelMenuClick(e, 3)}
                        alt=""
                        className="menu-icon"
                      />
                      <FilterationComponents
                        isShow={showFilterOf == 3 ? true : false}
                        setShowFilterOf={setShowFilterOf}
                      />
                    </span>
                  </th>
                  <th onClick={() => handelSortBy("Dr")} className="">
                    <span className=" w-100 d-flex align-items justify-content-between">
                      <span>
                        Dr{" "}
                        <img
                          class={sortBy == "Dr" ? (up ? "updown" : "") : ""}
                          src={arrow}
                          alt=""
                        />
                      </span>{" "}
                      <img
                        src={menu}
                        alt=""
                        onClick={(e) => handelMenuClick(e, 4)}
                        className="menu-icon"
                      />
                    </span>
                    <FilterationComponents
                      isShow={showFilterOf == 4 ? true : false}
                      setShowFilterOf={setShowFilterOf}
                    />
                  </th>
                  <th onClick={() => handelSortBy("Cr")} className="">
                    <span className=" w-100 d-flex align-items justify-content-between">
                      <span>
                        Cr{" "}
                        <img
                          class={sortBy == "Cr" ? (up ? "updown" : "") : ""}
                          src={arrow}
                          alt=""
                        />
                      </span>{" "}
                      <img
                        src={menu}
                        alt=""
                        onClick={(e) => handelMenuClick(e, 5)}
                        className="menu-icon"
                      />
                    </span>
                    <FilterationComponents
                      isShow={showFilterOf == 5 ? true : false}
                      setShowFilterOf={setShowFilterOf}
                    />
                  </th>
                  <th onClick={() => handelSortBy("Balance")} className="">
                    <span className=" w-100 d-flex align-items justify-content-between">
                      <span>
                        Balance{" "}
                        <img
                          class={
                            sortBy == "Balance" ? (up ? "updown" : "") : ""
                          }
                          src={arrow}
                          alt=""
                        />
                      </span>{" "}
                      <img
                        src={menu}
                        alt=""
                        onClick={(e) => handelMenuClick(e, 6)}
                        className="menu-icon"
                      />
                      <FilterationComponents
                        isShow={showFilterOf == 6 ? true : false}
                        setShowFilterOf={setShowFilterOf}
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="border">
                {statementData.map((stetment, index) => {
                  console.log(stetment);

                  return (
                    <tr key={index}>
                      <td>{`${convertISOToCustomDate(stetment.date)}`}</td>
                      <td>
                        <label className="lable">{stetment.type}</label>
                      </td>
                      <td>
                        {condinationRender(stetment.remarks, stetment.description)}
                      </td>
                      <td>
                        {stetment.type == "Debit" ? stetment.amount : "-"}
                      </td>
                      <td>
                        {stetment.type == "Credit" ? stetment.amount : "-"}
                      </td>
                      <td>{stetment.balance}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ))
        }
      </div>
      <div className="footer  d-flex align-items-center justify-content-end ">
        <section className="me-2">
          {(pageNo - 1) * show + 1} to {Math.min(pageNo * show, totalData)} of{" "}
          {totalData}{" "}
        </section>
        <section>
          <button onClick={goToFirstPage}>
            <img src={leftStop} alt="" />
          </button>
          <button onClick={pageDown}>
            <img src={left} alt="" />
          </button>
          <span>
            page {pageNo} of {totalPage}
          </span>
          <button onClick={pageUp}>
            <img src={right} alt="" />
          </button>
          <button onClick={goToLastPage}>
            <img src={rightStop} alt="" />
          </button>
        </section>
      </div>
    </div>
  );
};

export default AccountStatemantPage;
